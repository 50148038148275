import { useAppContext } from "context"
import React, { useEffect } from "react"
import { closeClass } from "utils/closeClass"
import { useBoringMeterEmitter } from "utils/Emits"

const BoringMeter = () => {
	const { socketPoints, setValue } = useAppContext()
	const { joinBoringMeterSocket } = useBoringMeterEmitter()

	const boringMeterSocketConnect = () => {
		// joinBoringMeterSocket();
		console.debug("@boringMeterSocket Connected")
	}

	// const boringMeterSocketConnected = () => {
	// 	console.debug("@boringMeterSocket Connected via custom");
	// 	joinBoringMeterSocket();
	// });

	const boringMeterSocketIoReconnect = attempt => {
		console.debug("@boringMeterSocket reconnect", attempt)
		joinBoringMeterSocket()
	}

	const boringMeterSocketIoReconnectAttempt = attempt => {
		console.debug("@boringMeterSocket reconnect_attempt", attempt)
	}
	const boringMeterSocketIoReconnectError = error => {
		console.debug("@boringMeterSocket reconnect_error", error.message)
	}
	const boringMeterSocketIoReconnectFailed = () => {
		console.debug("@boringMeterSocket reconnect_failed")
		setValue(state => ({
			...state,
			socketError: true,
		}))
		closeClass("boringMeterSocketError")
	}

	const boringMeterSocketDisconnect = reason => {
		console.debug("@boringMeterSocket Disconnected: ", reason)
		setValue(state => ({
			...state,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					boringMeterSocket: true,
				},
				error: { ...state.socketState.error, boringMeterSocket: true },
			},
		}))
	}

	const boringMeterSocketError = data => {
		console.error("@boringMeterSocket ERROR: ", data)
		setValue(state => ({
			...state,
			socketError: true,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					boringMeterSocket: false,
				},
				error: { ...state.socketState.error, boringMeterSocket: false },
			},
		}))
		closeClass("boringMeterSocketError")
	}

	const boringMeterSocketJoined = data => {
		console.debug("@boringMeterSocket Joined: ", data)
		setValue(state => ({
			...state,
			avgRating: data.avgRating,
			boringMeterRoomId: data.roomId,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					boringMeterSocket: true,
				},
				error: { ...state.socketState.error, boringMeterSocket: false },
			},
		}))
	}

	const boringMeterSocketAvgRating = ({ avgRating }) => {
		console.debug("@boringMeterSocketavgRating =:", { avgRating })
		setValue(state => ({ ...state, avgRating: avgRating }))
	}

	useEffect(() => {
		socketPoints.boringMeterSocket.on("connect", boringMeterSocketConnect)
		// socketPoints.boringMeterSocket.on("connected", boringMeterSocketConnected);
		socketPoints.boringMeterSocket.io.on(
			"reconnect",
			boringMeterSocketIoReconnect
		)
		socketPoints.boringMeterSocket.io.on(
			"reconnect_attempt",
			boringMeterSocketIoReconnectAttempt
		)
		socketPoints.boringMeterSocket.io.on(
			"reconnect_error",
			boringMeterSocketIoReconnectError
		)
		socketPoints.boringMeterSocket.io.on(
			"reconnect_failed",
			boringMeterSocketIoReconnectFailed
		)
		socketPoints.boringMeterSocket.on(
			"disconnect",
			boringMeterSocketDisconnect
		)
		socketPoints.boringMeterSocket.on("error", boringMeterSocketError)
		socketPoints.boringMeterSocket.on("joined", boringMeterSocketJoined)
		socketPoints.boringMeterSocket.on(
			"avgRating",
			boringMeterSocketAvgRating
		)

		return () => {
			socketPoints.boringMeterSocket.off(
				"connect",
				boringMeterSocketConnect
			)
			// socketPoints.boringMeterSocket.off("connected", boringMeterSocketConnected);
			socketPoints.boringMeterSocket.io.off(
				"reconnect",
				boringMeterSocketIoReconnect
			)
			socketPoints.boringMeterSocket.io.off(
				"reconnect_attempt",
				boringMeterSocketIoReconnectAttempt
			)
			socketPoints.boringMeterSocket.io.off(
				"reconnect_error",
				boringMeterSocketIoReconnectError
			)
			socketPoints.boringMeterSocket.io.off(
				"reconnect_failed",
				boringMeterSocketIoReconnectFailed
			)
			socketPoints.boringMeterSocket.off(
				"disconnect",
				boringMeterSocketDisconnect
			)
			socketPoints.boringMeterSocket.off("error", boringMeterSocketError)
			socketPoints.boringMeterSocket.off(
				"joined",
				boringMeterSocketJoined
			)
			socketPoints.boringMeterSocket.off(
				"avgRating",
				boringMeterSocketAvgRating
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <></>
}

export default BoringMeter
