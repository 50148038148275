import { useAppContext } from "context"
import React, { useEffect } from "react"
import { closeClass } from "utils/closeClass"
import { useSessionEmitter } from "utils/Emits"

const SessionEvents = () => {
	const { socketPoints, setValue } = useAppContext()
	const { joinSessionSocket } = useSessionEmitter()

	const sessionSocketConnect = () => {
		// joinSessionSocket();
		console.debug("@sessionSocket Connected")
	}

	// const sessionSocketConnected = () => {
	// 	console.debug("@sessionSocket Connected via custom");
	// 	joinSessionSocket();
	// });

	const sessionSocketIoReconnect = attempt => {
		console.debug("@sessionSocket reconnect", attempt)
		joinSessionSocket()
	}

	const sessionSocketIoReconnectAttempt = attempt => {
		console.debug("@sessionSocket reconnect_attempt", attempt)
	}
	const sessionSocketIoReconnectError = error => {
		console.debug("@sessionSocket reconnect_error", error.message)
	}
	const sessionSocketIoReconnectFailed = () => {
		console.debug("@sessionSocket reconnect_failed")
		setValue(state => ({
			...state,
			socketError: true,
		}))
		closeClass("sessionSocketError")
	}

	const sessionSocketDisconnect = reason => {
		console.debug("@sessionSocket Disconnected: ", reason)
		setValue(state => ({
			...state,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					sessionSocket: false,
				},
				error: { ...state.socketState.error, sessionSocket: false },
			},
		}))
	}

	const sessionSocketError = data => {
		console.error("@sessionSocket ERROR: ", data) // check tyep and et err mesg
		setValue(state => ({
			...state,
			socketError: true,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					sessionSocket: false,
				},
				error: { ...state.socketState.error, sessionSocket: false },
			},
		}))
		closeClass("sessionSocketError")
	}

	const sessionSocketJoined = () => {
		console.debug("@sessionSocket Joined")
		setValue(state => ({
			...state,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					sessionSocket: true,
				},
				error: { ...state.socketState.error, sessionSocket: false },
			},
		}))
	}

	const sessionSocketSession = () => {
		console.debug("@sessionSocket Session")
	}

	useEffect(() => {
		socketPoints.sessionSocket.on("connect", sessionSocketConnect)
		// socketPoints.sessionSocket.on("connected", sessionSocketConnected);
		socketPoints.sessionSocket.io.on("reconnect", sessionSocketIoReconnect)
		socketPoints.sessionSocket.io.on(
			"reconnect_attempt",
			sessionSocketIoReconnectAttempt
		)
		socketPoints.sessionSocket.io.on(
			"reconnect_error",
			sessionSocketIoReconnectError
		)
		socketPoints.sessionSocket.io.on(
			"reconnect_failed",
			sessionSocketIoReconnectFailed
		)
		socketPoints.sessionSocket.on("disconnect", sessionSocketDisconnect)
		socketPoints.sessionSocket.on("error", sessionSocketError)
		socketPoints.sessionSocket.on("joined", sessionSocketJoined)
		socketPoints.sessionSocket.on("session", sessionSocketSession)

		return () => {
			socketPoints.sessionSocket.off("connect", sessionSocketConnect)
			// socketPoints.sessionSocket.off("connected", sessionSocketConnected);
			socketPoints.sessionSocket.io.off(
				"reconnect",
				sessionSocketIoReconnect
			)
			socketPoints.sessionSocket.io.off(
				"reconnect_attempt",
				sessionSocketIoReconnectAttempt
			)
			socketPoints.sessionSocket.io.off(
				"reconnect_error",
				sessionSocketIoReconnectError
			)
			socketPoints.sessionSocket.io.off(
				"reconnect_failed",
				sessionSocketIoReconnectFailed
			)
			socketPoints.sessionSocket.off(
				"disconnect",
				sessionSocketDisconnect
			)
			socketPoints.sessionSocket.off("error", sessionSocketError)
			socketPoints.sessionSocket.off("joined", sessionSocketJoined)
			socketPoints.sessionSocket.off("session", sessionSocketSession)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <></>
}

export default SessionEvents
