import { useAppContext } from "context"
import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { UAParser } from "ua-parser-js"
import { useMeetingEmitter, useSessionEmitter } from "utils/Emits"

const SessionLogger = () => {
	const {
		programCode,
		meetingRoomId,
		classCode,
		classDate,
		videoTimeStamp,
		setValue,
	} = useAppContext()
	const { sendSession, sendProgress } = useSessionEmitter()
	const { action } = useMeetingEmitter()
	const hidden = useRef(null)
	const state = useRef(null)
	const visibilityChange = useRef(null)
	const switchListener = useRef(null)
	const focusListener = useRef(null)
	const date = useRef(new Date())
	const newDate = useRef(new Date())
	const dateStamp = useRef(new Date())
	const lastSession = useRef(new Date())
	const timeSpent = useRef(0)
	const focusLost = useRef(false)
	const isIdle = useRef(false)
	const perMinSessionRunning = useRef(true)
	const perMinSession = useRef()
	let parser = new UAParser()
	let deviceParser = parser.getResult()
	const location = useLocation()

	// Function that does something
	const reportEvent = (message, date, newDate) => {
		timeSpent.current = Math.round((newDate.current - date.current) / 1000)
		if (timeSpent.current > 0 && lastSession.current !== newDate.current) {
			// && listen) {
			let text = `[${message}] active for ${timeSpent.current} seconds`
			console.debug(text)
			// console.debug(message, { timespent, lastSession: lastSession.toLocaleTimeString(), date: date.toLocaleTimeString(), newDate: newDate.toLocaleTimeString() });
			sendSession({
				programCode,
				classCode,
				seekTime: videoTimeStamp,
				timespent: timeSpent.current,
				activity: message,
				source: {
					device: `${deviceParser.device.vendor} ${deviceParser.device.model}`,
					platform: deviceParser.os.name,
					url: window.location.host,
				},
			})
			localStorage.getItem("plyrPlaying") === "true" &&
				sendProgress({
					programCode,
					classCode,
					seekTime: videoTimeStamp,
					timespent: timeSpent.current,
					activity: message,
					source: {
						device: `${deviceParser.device.vendor} ${deviceParser.device.model}`,
						platform: deviceParser.os.name,
						url: window.location.host,
					},
				})
			clearInterval(perMinSession.current)
			localStorage.setItem("timeSpent", 0)
			setValue(prev => ({ ...prev, timeSpent: 0 }))
			lastSession.current = new Date()
			date.current = new Date()
			perMinSession.current = setInterval(() => sendPerMinute(), 1 * 1000)
		}
	}

	// eslint-disable-next-line no-unused-vars
	const reportIdleEvent = () => {
		setTimeout(() => {
			newDate.current = new Date()
			timeSpent.current = Math.round(
				(newDate.current - date.current) / 1000
			)
			if (timeSpent >= 1 && !isIdle) {
				isIdle.current = true
				let text =
					"[ IDLE ] active for " + timeSpent.current + " seconds"
				console.debug(text)
				fetch("http://localhost:5000/reportIdleEvent", {
					method: "POST",
					body: JSON.stringify({ timeSpent: text }),
					header: {
						"Content-Type": "application/json",
					},
				})
			}
		}, 5 * 1000)
		date.current = new Date()
		isIdle.current = false
	}

	const sendPerMinute = () => {
		dateStamp.current = new Date()
		localStorage.setItem(
			"timeSpent",
			Math.round(
				Math.round(dateStamp.current - lastSession.current) / 1000
			)
		)
		setValue(prev => ({
			...prev,
			timeSpent: Math.round(
				Math.round(dateStamp.current - lastSession.current) / 1000
			),
		}))
		if (
			Math.round(
				Math.round(dateStamp.current - lastSession.current) / 1000
			) === 30
		) {
			// console.debug(Math.round(lastSession.current - lastSession.current) / 1000)
			newDate.current = new Date()
			reportEvent("Time Interval", lastSession, newDate)
			date.current = new Date()
		}
	}

	useEffect(() => {
		if (new Date(classDate) <= new Date() && location.pathname === "/") {
			console.debug("Session Tracking Started")
			date.current = new Date()
			newDate.current = new Date()
			dateStamp.current = new Date()
			lastSession.current = new Date()
			perMinSession.current = setInterval(() => sendPerMinute(), 1 * 1000)

			if (document.hidden != null) {
				hidden.current = "hidden"
				visibilityChange.current = "visibilitychange"
				state.current = "visibilityState"
			} else if (document.mozHidden != null) {
				hidden.current = "mozHidden"
				visibilityChange.current = "mozvisibilitychange"
				state.current = "mozVisibilityState"
			} else if (document.msHidden != null) {
				hidden.current = "msHidden"
				visibilityChange.current = "msvisibilitychange"
				state.current = "msVisibilityState"
			} else if (document.webkitHidden != null) {
				hidden.current = "webkitHidden"
				visibilityChange.current = "webkitvisibilitychange"
				state.current = "webkitVisibilityState"
			}
			document.addEventListener(
				visibilityChange.current,
				e => {
					if (document[state] === "visible") {
						date.current = new Date()
					} else if (document[hidden]) {
						newDate.current = new Date()
						reportEvent("Changed Tab", date, newDate)
						action({
							metaData: {},
							roomId: meetingRoomId || classCode,
							action: "changedTab",
						})
						// reportIdleEvent()
					}
				},
				false
			)

			// Calculates Time Spent on page upon switching windows
			focusListener.current = setInterval(() => {
				if (!document.hasFocus() && !focusLost.current) {
					focusLost.current = true
					newDate.current = new Date()
					reportEvent("Switched Window", date, newDate)
					date.current = new Date()
				} else if (document.hasFocus()) {
					focusLost.current = false
				}
			}, 200)

			// // Calculates Time Spent on page upon switching windows
			switchListener.current = setInterval(() => {
				if (
					!document.hasFocus() &&
					focusLost.current &&
					perMinSessionRunning.current
				) {
					focusLost.current = true
					clearInterval(perMinSession.current)
					localStorage.setItem("timeSpent", 0)
					setValue(prev => ({ ...prev, timeSpent: 0 }))
					perMinSessionRunning.current = false
				} else if (
					document.hasFocus() &&
					!focusLost.current &&
					!perMinSessionRunning.current
				) {
					focusLost.current = false
					date.current = new Date()
					lastSession.current = new Date()
					perMinSession.current = setInterval(
						() => sendPerMinute(),
						1 * 1000
					)
					perMinSessionRunning.current = true
				}
			}, 200)

			return () => {
				clearInterval(perMinSession.current)
				clearInterval(focusListener.current)
				clearInterval(switchListener.current)
				newDate.current = new Date()
				document.removeEventListener(visibilityChange.current, e => {
					if (document[state] === "visible") {
						date.current = new Date()
					} else if (document[hidden]) {
						newDate.current = new Date()
						reportEvent("Changed Tab", date, newDate)
						action({
							metaData: {},
							roomId: meetingRoomId || classCode,
							action: "changedTab",
						})
					}
				})
				reportEvent("Left Page", date, newDate)
			}
		} else console.debug("Session Tracking Stopped")
		clearInterval(perMinSession.current)
		clearInterval(focusListener.current)
		clearInterval(switchListener.current)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname])

	return <></>
}

export default SessionLogger
