import { useDyteMeeting, useDyteSelector } from "@dytesdk/react-web-core"
import { DotsVerticalIcon } from "@heroicons/react/solid"
import { IconButton, Menu, MenuItem } from "@material-ui/core"
import MicOffIcon from "@material-ui/icons/MicOffOutlined"
import MicIcon from "@material-ui/icons/MicOutlined"
import VideocamSolidIcon from "@material-ui/icons/Videocam"
import VideocamOffIcon from "@material-ui/icons/VideocamOffOutlined"
import VideocamIcon from "@material-ui/icons/VideocamOutlined"
import { useAppContext } from "context"
import { useState } from "react"
import { useMeetingEmitter } from "utils/Emits"

export const MeetingParticipantImage = ({ participant }) => {
	const { user, activeSpeaker } = useAppContext()
	const isSelf = user.participantId === participant.participantId
	const dyteParticipant = useDyteSelector(m =>
		isSelf ? m.self : m.participants.joined.get(participant.participantId)
	)

	return (
		<>
			<div className="square relative flex justify-center items-center">
				{activeSpeaker === participant.participantId &&
					dyteParticipant?.audioEnabled && (
						<span className="absolute inset-0 -left-1 h-12 m-auto square z-0 rounded-2xl overflow-hidden bg-green-500 animate-pulse" />
					)}
				<img
					src={
						participant.profileImageUrl
							? participant.profileImageUrl
							: `https://avatars.dicebear.com/api/initials/${participant.name}.svg`
					}
					alt={participant.name}
					className="h-10 rounded-md square relative z-10"
				/>
				{dyteParticipant?.videoEnabled && (
					<span className="absolute z-10 -bottom-1 -right-1 h-[18px] w-[18px] flex justify-center items-center rounded-full square bg-white shadow">
						<VideocamSolidIcon className="text-red-500 !h-3" />
					</span>
				)}
			</div>
		</>
	)
}

export const MeetingParticipantControl = ({ participant }) => {
	const { user, classCode, meetingRoomId } = useAppContext()
	const { cameraToggle, micToggle } = useMeetingEmitter()
	const [anchorEl, setAnchorEl] = useState(null)
	const { meeting } = useDyteMeeting()
	const isSelf = user.participantId === participant.participantId
	const dyteParticipant = useDyteSelector(m =>
		isSelf ? m.self : m.participants.joined.get(participant.participantId)
	)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	// const handleKick = (participantId) => {
	// 	kick({
	// 		participantId,
	// 		roomId: meetingRoomId || classCode,
	// 	});
	// 	meeting.participants.kick(participantId);
	// 	setValue((state) => ({
	// 		...state,
	// 		participants: state.participants.map((p) => {
	// 			if (p.participantId === participantId) {
	// 				p.handRaiseStatus = null;
	// 				delete p.participantId;
	// 			}
	// 			return p;
	// 		}),
	// 	}));
	// };

	const handleMic = async () => {
		if (dyteParticipant?.audioEnabled) {
			await meeting.participants.disableAudio(participant.participantId)
		} else {
			micToggle({
				participantId: participant.participantId,
				roomId: meetingRoomId || classCode,
			})
		}
	}

	const handleCam = async () => {
		if (dyteParticipant?.videoEnabled) {
			await meeting.participants.disableVideo(participant.participantId)
		} else {
			cameraToggle({
				participantId: participant.participantId,
				roomId: meetingRoomId || classCode,
			})
		}
	}

	// const handleRemove = (participantId) => {
	// 	remove({
	// 		participantId,
	// 		roomId: meetingRoomId || classCode,
	// 	});
	// 	setValue((state) => ({
	// 		...state,
	// 		participants: state.participants.map((p) => {
	// 			if (p.participantId === participantId) {
	// 				p.handRaiseStatus = null;
	// 				delete p.participantId;
	// 			}
	// 			return p;
	// 		}),
	// 	}));
	// };

	return (
		<>
			<IconButton onClick={e => handleClick(e)}>
				<DotsVerticalIcon
					className="w-4 h-4 opacity-50"
					aria-hidden="true"
				/>
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				onClick={handleClose}
				className="!shadow"
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem onClick={handleCam}>
					{dyteParticipant?.videoEnabled ? (
						<div className="flex items-center space-x-2">
							<VideocamIcon className="!h-5 rounded-md square !text-gray-500" />
							<span className="text-sm text-gray-500">
								Turn Off Camera
							</span>
						</div>
					) : (
						<div className="flex items-center space-x-2">
							<VideocamOffIcon className="!h-5 rounded-md square !text-gray-500" />
							<span className="text-sm text-gray-500">
								Turn On Camera
							</span>
						</div>
					)}
				</MenuItem>
				<MenuItem onClick={handleMic}>
					{dyteParticipant?.audioEnabled ? (
						<div className="flex items-center space-x-2">
							<MicIcon className="!h-5 rounded-md square !text-gray-500" />
							<span className="text-sm text-gray-500">
								Turn Off Mic
							</span>
						</div>
					) : (
						<div className="flex items-center space-x-2">
							<MicOffIcon className="!h-5 rounded-md square !text-gray-500" />
							<span className="text-sm text-gray-500">
								Turn On Mic
							</span>
						</div>
					)}
				</MenuItem>
				{/* <MenuItem onClick={() => handleKick(participant.participantId)}>
					<div className='flex items-center space-x-2'>
						<RemoveCircleOutlineIcon className='!h-5 rounded-md square !text-gray-500' />
						<span className='text-sm text-gray-500'>
							Kick from Meeting
						</span>
					</div>
				</MenuItem> */}
				{/* <MenuItem
					onClick={() => handleRemove(participant.participantId)}
				>
					<div className='flex items-center space-x-2'>
						<ExitToAppIcon className='h-8 rounded-md square' />
						<span className='text-sm text-gray-900'>
							Remove from Class
						</span>
					</div>
				</MenuItem> */}
			</Menu>
		</>
	)
}
