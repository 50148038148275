import { useAppContext } from "context"

const useBoringMeterEmitter = () => {
	const { programCode, classCode, socketPoints, videoTimeStamp } =
		useAppContext()

	const joinBoringMeterSocket = () => {
		socketPoints.boringMeterSocket.emit("join", {
			programCode,
			classCode,
		})
		console.debug('boringMeterSocket.emit("join")', {
			programCode,
			classCode,
		})
	}

	const setRating = data => {
		socketPoints.boringMeterSocket.emit("rate", {
			...data,
			seekTime: videoTimeStamp,
		})
		console.debug("@boringMeterSocket.emit('rate'):", {
			...data,
			seekTime: videoTimeStamp,
		})
	}

	return { joinBoringMeterSocket, setRating }
}

export default useBoringMeterEmitter
