import { RefreshIcon } from "@heroicons/react/outline"
import { IconButton } from "@material-ui/core"
import { useAppContext } from "context"
import classNames from "utils/classNames"
import { usePasteBinEmitter } from "utils/Emits"
import Agenda from "./Agenda"

const AgendaList = () => {
	const { socketState } = useAppContext()
	const { joinPasteBinSocket } = usePasteBinEmitter()

	return (
		<>
			<div className="relative h-full">
				{(socketState.connection.pasteBinSocket === false ||
					socketState.error.pasteBinSocket === true) && (
					<div className="absolute inset-0 w-full z-50 bg-transparent flex justify-center items-center">
						<IconButton onClick={joinPasteBinSocket}>
							<RefreshIcon className="w-6 h-6 text-red-500 hover:animate-spin" />
						</IconButton>
					</div>
				)}
				<div
					className={classNames(
						socketState.connection.pasteBinSocket === false ||
							socketState.error.pasteBinSocket === true
							? "blur-[2px]"
							: "",
						"h-full"
					)}
				>
					<ul className="flex flex-col w-full md:!h-screen overflow-y-scroll scrollbar-hide divide-y divide-gray-200 pt-0.5 h-full">
						{/* <h1 className='text-lg text-gray-800 mb-4 px-4'>
							Today's Agenda
						</h1> */}
						<Agenda />
					</ul>
				</div>
			</div>
		</>
	)
}

export default AgendaList
