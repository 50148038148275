import Class from "pages/Class"
// import Feedback from "pages/Feedback";
import Join from "pages/Join"
import React from "react"
import { Route, Switch } from "react-router-dom"
import Events from "utils/Events"
import SessionLogger from "utils/SessionLogger"

const App = () => {
	return (
		<>
			<Switch>
				<Route
					exact
					path="/"
					component={Class}
				/>
				<Route
					exact
					path="/join"
					component={Join}
				/>
				{/* <Route exact path='/feedback' component={Feedback} /> */}
			</Switch>
			<Events />
			<SessionLogger />
		</>
	)
}

export default App
