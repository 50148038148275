import { useAppContext } from "context"

const useSessionEmitter = () => {
	const { socketPoints, programCode, classCode } = useAppContext()

	const joinSessionSocket = () => {
		socketPoints.sessionSocket.emit("join", {
			programCode,
			classCode,
		})
		console.debug('sessionSocket.emit("join")', { programCode, classCode })
	}

	const sendSession = data => {
		socketPoints.sessionSocket.emit("session", data)
		console.debug("@sessionSocket.emit('session'):", data)
	}

	const sendProgress = data => {
		socketPoints.sessionSocket.emit("progress", data)
		console.debug("@sessionSocket.emit('progress'):", data)
	}

	return {
		joinSessionSocket,
		sendSession,
		sendProgress,
	}
}

export default useSessionEmitter
