import { Loader, MeetingView, TabsView } from "components"
import { useAppContext } from "context"
import classNames from "utils/classNames"
import useBreakpoints from "utils/useBreakPoints"
import Meeting from "./Meeting"

// const getWidth = () =>
// 	window.innerWidth ||
// 	document.documentElement.clientWidth ||
// 	document.body.clientWidth;

const Class = () => {
	const { isPast, sideBarOpen, user } = useAppContext()
	const { isMD } = useBreakpoints()

	return user.uid ? (
		<>
			{/* <InternetSpeedMeter /> */}
			<Meeting>
				<div className="flex sm:flex-row flex-col bg-white h-screen select-none fixed inset-0">
					<div
						className={classNames(
							"sm:w-0 w-full sm:flex-1 bg-white",
							isPast ? "sm:h-screen" : "h-screen"
						)}
					>
						<MeetingView />
					</div>
					{isMD && (
						<div
							className={classNames(
								"border-l-[1px]",
								sideBarOpen
									? "w-full lg:w-3/12 md:w-4/12"
									: "w-auto"
							)}
						>
							<TabsView />
						</div>
					)}
				</div>
			</Meeting>
		</>
	) : (
		<div className="h-screen w-screen flex items-center justify-center bg-white">
			<Loader />
		</div>
	)
}

export default Class
