import { useAppContext } from "context"
import { useEffect, useState } from "react"
import axios from "utils/axios"

const useGetPasteBin = ({ pageNo }) => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [hasMore, setHasMore] = useState(false)
	const { resources, resourcesPage, classCode, setValue } = useAppContext()

	useEffect(() => {
		setError(false)
		if (resourcesPage === pageNo)
			setValue(state => ({
				...state,
				resources,
			}))
		else {
			setLoading(true)
			axios({
				method: "GET",
				url: `https://service.letsupgrade.in/v1/files/${classCode}`,
				params: { page: pageNo, limit: 10 },
			})
				.then(res => {
					if (res.data.results.data.length)
						setValue(state => ({
							...state,
							resources: [
								...state.resources,
								...res.data.results.data,
							],
							resourcesPage: state.resourcesPage + 1,
						}))
					setHasMore(res.data.results.data.length > 0)
					setLoading(false)
				})
				.catch(e => {
					if (axios.isCancel(e)) return
					setLoading(false)
					setError(true)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNo])

	return { loading, error, hasMore }
}

export default useGetPasteBin
