import { ArrowSmDownIcon, EmojiHappyIcon, XIcon } from "@heroicons/react/solid"
import { PaperAirplaneIcon } from "@heroicons/react/outline"
import { Typography } from "@material-ui/core"
import { useAppContext } from "context"
import React, { Fragment, useEffect, useRef, useState } from "react"
import classNames from "utils/classNames"
// import getHex from "utils/getHex";
import { isInViewport, scrollIntoView } from "utils/viewPort"
import { Popover, Transition } from "@headlessui/react"

// const emojiArr = [
// 	{ emoji: "+1", gif: "/assets/img/reactions/thumbs_up.gif" },
// 	{ emoji: "heart", gif: "/assets/img/reactions/sparkling_heart.gif" },
// 	{ emoji: "grin", gif: "/assets/img/reactions/beaming_face_with_smiling_eyes.gif" },
// 	{ emoji: "tada", gif: "/assets/img/reactions/party_popper.gif" },
// 	{ emoji: "thinking", gif: "/assets/img/reactions/thinking_face.gif" },
// 	{ emoji: "-1", gif: "/assets/img/reactions/thumbs_down.gif" },
// ];

const emojiArr = [
	{ emoji: "👍", gif: "/assets/img/reactions/thumbs_up.gif" },
	{ emoji: "❤️", gif: "/assets/img/reactions/sparkling_heart.gif" },
	{
		emoji: "😄",
		gif: "/assets/img/reactions/beaming_face_with_smiling_eyes.gif",
	},
	{ emoji: "🎉", gif: "/assets/img/reactions/party_popper.gif" },
	{ emoji: "🤔", gif: "/assets/img/reactions/thinking_face.gif" },
	{ emoji: "👎", gif: "/assets/img/reactions/thumbs_down.gif" },
]

const Input = ({ handleSendMessage, msgToReplyTo, cancelReply }) => {
	const { unread, user, setValue } = useAppContext()
	const [msg, setMsg] = useState("")
	const [counter, setCounter] = useState(user.misc.chatState.time - 1)
	const msgRef = useRef()
	const inputRef = useRef()

	const sendMessage = e => {
		e?.preventDefault()
		if (msg.length) {
			handleSendMessage(msg)
		}
		msgRef.current.style.height = "inherit"
		setMsg("")
	}

	const handlePress = e => {
		e.target.style.height = "inherit"

		// Get the computed styles for the element
		var computed = window.getComputedStyle(e.target)

		// Calculate the height
		var height =
			parseInt(computed.getPropertyValue("border-top-width"), 10) +
			e.target.scrollHeight +
			parseInt(computed.getPropertyValue("border-bottom-width"), 10)

		if (height < 100) e.target.style.height = height + "px"
		else e.target.style.height = "100px"
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault()
			sendMessage()
		}
	}

	useEffect(() => {
		if (msgToReplyTo !== null && typeof msgToReplyTo === "object")
			msgRef.current.focus()
	}, [msgToReplyTo])

	// const padTime = (time) => {
	// 	return String(time).length === 1 ? `0${time}` : `${time}`;
	// };

	// const format = (time) => {
	// 	const minutes = Math.floor(time / 60);
	// 	const seconds = time % 60;
	// 	return `${minutes}:${padTime(seconds)}`;
	// };

	useEffect(() => {
		let timer
		if (counter > 0) {
			timer = setTimeout(() => setCounter(c => c - 1), 1000)
		}

		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [counter])

	// useEffect(() => {
	// 	if (user.misc.chatState.time > 0) {
	// 		setCounter(user.misc.chatState.time - 1);
	// 		setTimeout(() => {
	// 			setValue((state) => ({
	// 				...state,
	// 				user: {
	// 					...state.user,
	// 					misc: {
	// 						...state.user.misc,
	// 						chatState: {
	// 							banned: false,
	// 							time: 0,
	// 						},
	// 					},
	// 				},
	// 			}));
	// 		}, user.misc.chatState.time * 1000);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [user.misc.chatState.time]);

	return (
		<>
			{unread.chat && !isInViewport("#chatEnd") ? (
				<div className="py-2 bg-transparent flex items-center justify-center">
					<span
						className="max-w-full rounded-md py-1 px-3 flex text-xs items-center font-medium bg-gray-900 text-white"
						onClick={() =>
							scrollIntoView("#chatContainer", "chat", setValue)
						}
					>
						<span>
							{unread.chat} New Message
							{unread.chat > 1 ? "s" : ""}
						</span>
						<ArrowSmDownIcon className="w-4 h-4" />
					</span>
				</div>
			) : null}
			{msgToReplyTo && (
				<div className="flex flex-col px-4 py-4 sm:px-6 border-t-[1px] border-gray-100 bg-white">
					<div
						className={classNames(
							"border-l-[4px] group-scoped cursor-pointer  p-2 rounded-md flex items-center bg-gray-100"
						)}
						// style={{ borderColor: getHex(msgToReplyTo.user?.uid) }}
					>
						<div className="flex-1">
							<div className="flex justify-between items-center">
								<Typography
									variant="subtitle2"
									className="select-text"
									// style={{
									// 	color: getHex(msgToReplyTo.user?.uid),
									// }}
								>
									{msgToReplyTo.user?.name}
								</Typography>
							</div>
							<Typography
								variant="subtitle2"
								className="break-words text-truncate"
							>
								{msgToReplyTo.message}
							</Typography>
						</div>
						<button
							type="button"
							className="inline-flex justify-center p-2 text-sm font-medium text-black hover:bg-gray-200 rounded-full"
							onClick={cancelReply}
						>
							<XIcon className="h-4 w-4" />
						</button>
					</div>
				</div>
			)}
			<div className="px-1 py-3 border-t-[1px] bg-white">
				<div className="relative flex">
					<form
						onSubmit={sendMessage}
						className={classNames(
							"w-full",
							user.misc?.chatState.banned
								? "pointer-events-none cursor-not-allowed"
								: ""
						)}
						ref={inputRef}
					>
						<Popover className="absolute inset-y-0 left-0 inline-flex items-center px-2 text-sm text-gray-500">
							{({ open }) => (
								<>
									<Popover.Button
										className={classNames(
											"group inline-flex items-center rounded-full"
										)}
									>
										<EmojiHappyIcon className="h-6 w-6 text-gray-500" />
									</Popover.Button>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-500"
										enterFrom="opacity-0 translate-y-1"
										enterTo="opacity-100 translate-y-0"
										leave="transition ease-in duration-150"
										leaveFrom="opacity-100 translate-y-0"
										leaveTo="opacity-0 translate-y-1"
									>
										<Popover.Panel className="absolute bottom-full -left-1 z-10 w-max transform">
											<div className="overflow-hidden bg-white shadow-md border rounded-full px-3 py-2 flex flex-col items-center justify-center gap-2">
												{emojiArr.map(emoji => (
													<Popover.Button
														key={emoji.emoji}
													>
														{/* <Emoji
															className='text-white text-lg hover:scale-125 transition-all'
															emoji={emoji.emoji}
															selectEmoji={() => {
																setEmojiSelected(emoji);
																// addReaction(emoji);
															}}
														/> */}
														<img
															src={emoji.gif}
															className="w-6 h-6"
															alt=""
															onClick={() =>
																handleSendMessage(
																	emoji.emoji
																)
															}
														/>
													</Popover.Button>
												))}
											</div>
										</Popover.Panel>
									</Transition>
								</>
							)}
						</Popover>
						<textarea
							type="text"
							disabled={user.misc?.chatState.banned}
							placeholder={
								user.misc?.chatState.banned
									? "On Timeout"
									: "Write message..."
							}
							ref={msgRef}
							value={msg}
							onChange={e => setMsg(e.target.value)}
							// onKeyPress={handlePress}
							onKeyUp={handlePress}
							onKeyDown={handlePress}
							rows={1}
							className="flex-1 block w-full px-10 py-2 sm:text-sm focus:outline-none scrollbar-hide ring-0 border-transparent focus:ring-0 focus:border-transparent"
						/>
						<button
							type="submit"
							className="absolute inset-y-0 right-0 inline-flex items-center px-2 text-sm text-gray-500"
						>
							<PaperAirplaneIcon className="w-6 h-6 rotate-90" />
						</button>
						{/* {user.misc?.chatState.banned && (
							<p className='absolute text-xs text-gray-700 top-full'>
								{format(counter)}
							</p>
						)} */}
					</form>
				</div>
			</div>
		</>
	)
}

export default Input
