import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import App from "App"
import AppContextProvider from "context"
import "emoji-mart/css/emoji-mart.css"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import "./index.css"

process.env.NODE_ENV === "production" &&
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new BrowserTracing()],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	})

ReactDOM.render(
	<>
		<Router>
			<AppContextProvider>
				<App />
			</AppContextProvider>
		</Router>
	</>,
	document.getElementById("root")
)
