// import { Switch } from "@headlessui/react";
import { RefreshIcon } from "@heroicons/react/outline"
import { SearchIcon } from "@heroicons/react/solid"
import { IconButton, Tooltip, Typography } from "@material-ui/core"
import { useAppContext } from "context"
import { useState } from "react"
import { useMeetingEmitter } from "utils/Emits"
import classNames from "utils/classNames"
import { MeetingParticipantImage } from "./Meeting"

const Participant = ({ participant }) => {
	const { user } = useAppContext()

	return (
		<>
			<div className="flex items-center p-4 sm:px-6">
				<div className="w-full flex-1 flex items-center">
					<div className="flex-shrink-0">
						{participant.participantId ? (
							<MeetingParticipantImage
								participant={participant}
							/>
						) : (
							<img
								src={
									participant.profileImageUrl
										? participant.profileImageUrl
										: `https://avatars.dicebear.com/api/initials/${participant.name}.svg`
								}
								alt={participant.name}
								className="h-10 rounded-xl square"
							/>
						)}
					</div>
					<div className="min-w-0 flex-1 px-4 md:grid md:gap-1">
						<div className="w-[80%] truncate">
							<Tooltip
								title={`${participant.name} (${
									participant.role.charAt(0).toUpperCase() +
									participant.role.slice(1)
								})`}
							>
								<div className="flex space-x-1">
									<span
										className={classNames(
											"text-sm font-medium truncate text-gray-900",
											participant.participantId &&
												"!text-lu"
										)}
									>
										{user.uid === participant.uid
											? "You"
											: participant.name}
									</span>
									{["trainer", "admin", "moderator"].includes(
										participant.role.toLowerCase()
									) ? (
										<span
											className={classNames(
												"inline-flex items-center px-2.5 py-0.5 space-x-1 rounded text-xxs font-medium",
												participant.role === "trainer"
													? "bg-purple-100 text-purple-800"
													: participant.role ===
													  "trainer"
													? "bg-yellow-100 text-yellow-800"
													: "bg-green-100 text-green-800"
											)}
										>
											<span>
												{participant.role
													.charAt(0)
													.toUpperCase() +
													participant.role.slice(1)}
											</span>
										</span>
									) : null}
								</div>
							</Tooltip>
							<a
								href={`https://letsupgrade.in/user/${participant.username}`}
								target="_blank"
								className="flex items-center text-sm text-gray-500 hover:text-lu"
								rel="noreferrer"
							>
								<span className="truncate">
									@{participant.username}
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const Participants = () => {
	const { participants, socketState } = useAppContext()
	const { joinMeetingSocket } = useMeetingEmitter()
	const [search, setSearch] = useState("")

	return (
		<>
			<div className="relative">
				{(socketState.connection.meetingSocket === false ||
					socketState.error.meetingSocket === true) && (
					<div className="absolute inset-0 w-full z-50 bg-transparent flex justify-center items-center">
						<IconButton onClick={joinMeetingSocket}>
							<RefreshIcon className="w-6 h-6 text-red-500 hover:animate-spin" />
						</IconButton>
					</div>
				)}
				<div
					className={classNames(
						socketState.connection.meetingSocket === false ||
							socketState.error.meetingSocket === true
							? "blur-[2px]"
							: ""
					)}
				>
					<div className="flex flex-col w-full md:h-screen md:h-screen-ios h-sideContainer pt-5">
						{participants.length ? (
							<>
								<div className="p-4 pt-0">
									<div className="relative text-gray-500 focus-within:text-gray-500">
										<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
											<SearchIcon
												className="h-5 w-5"
												aria-hidden="true"
											/>
										</div>
										<input
											className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-300 bg-opacity-25 text-gray-500 placeholder-gray-500 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm focus:border-gray-300 focus:shadow"
											placeholder="Search User"
											onChange={e =>
												setSearch(e.target.value)
											}
											autoComplete="false"
										/>
									</div>
								</div>
								<ul className="bg-white shadow overflow-y-scroll scrollbar-hide divide-y divide-gray-200">
									{participants
										.filter(p =>
											p.name
												?.toLowerCase()
												?.includes(
													search?.toLowerCase()
												)
										)
										.sort((a, b) => {
											if (!a.participantId) return 1
											if (!b.participantId) return -1
											return 0
										})
										.map(participant => (
											<li key={participant.uid}>
												<Participant
													participant={participant}
												/>
											</li>
										))}
								</ul>
							</>
						) : (
							<>
								<div className="flex flex-col justify-center items-center space-x-1 text-black h-full select-none space-y-8">
									<img
										src="/assets/img/noParticipants.png"
										alt="No Participant's Yet"
										className="md:w-[60%] w-[25%]"
									/>
									<Typography className="text-gray-500">
										No Participant's Yet
									</Typography>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default Participants
