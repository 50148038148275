import { Dialog, Transition } from "@headlessui/react"
import { Avatar, Slider } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/styles"
import { useAppContext } from "context"
import { Fragment, useEffect, useState } from "react"
import { useBoringMeterEmitter } from "utils/Emits"

const BoringSlider = withStyles({
	root: {
		color: "#ff6600",
		height: 8,
	},
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: "#fff",
		border: "2px solid white",
		marginTop: -8,
		marginLeft: -12,
		boxShadow:
			"0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
		"&:focus, &:hover, &$active": {
			boxShadow:
				"0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
		},
	},
	valueLabel: {
		left: "calc(-50% + 8px)",
		top: -24,
		"& > span": {
			backgroundColor: "#000",
			width: 24,
			height: 24,
		},
	},
	track: {
		height: 8,
		borderRadius: 4,
	},
	rail: {
		height: 8,
		borderRadius: 4,
	},
})(Slider)

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		"& > *": {
			margin: 8,
		},
	},
	medium: {
		width: 56,
		height: 56,
		zIndex: 999,
	},
	large: {
		width: 72,
		height: 72,
		zIndex: 999,
	},
}))

const ShowBoringMeter = () => {
	const {
		avgRating,
		classCode,
		isLive,
		boringMeterRoomId,
		trainer,
		feedbackTimer,
	} = useAppContext()
	const { setRating } = useBoringMeterEmitter()
	const classes = useStyles()
	const [currentMood, setCurrentMood] = useState(0)
	const [meterVisible, setMeterVisible] = useState(false)
	const [meterThanksVisible, setMeterThanksVisible] = useState(false)

	useEffect(() => {
		let setBoringMeterVisible
		if (!meterVisible) {
			setBoringMeterVisible = setTimeout(() => {
				!window.hideMeter && isLive && setMeterVisible(true)
			}, feedbackTimer * 60 * 1000)
		}
		return () => clearTimeout(setBoringMeterVisible)
	}, [meterVisible, isLive, feedbackTimer])

	useEffect(() => {
		if (meterThanksVisible) {
			const setBoringMeterThanksVisible = setInterval(() => {
				setMeterThanksVisible(false)
			}, 5 * 1000)
			return () => clearInterval(setBoringMeterThanksVisible)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meterThanksVisible])

	return (
		<>
			<Transition
				show={meterVisible}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={() => setMeterVisible(false)}
				>
					<div className="max-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
						</Transition.Child>

						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>

						<Transition.Child
							as={Fragment}
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enterFrom="translate-y-full"
							enterTo="translate-y-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leaveFrom="translate-y-0"
							leaveTo="translate-y-full"
						>
							<div className="inline-flex flex-col max-w-2xl min-w-[280px] min-h-[200px] max-h-[75vh] p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
								<Dialog.Title
									as="h3"
									className="text-lg font-medium text-center leading-6 text-gray-900"
								>
									{trainer?.name} is asking
								</Dialog.Title>
								<div className="mt-2 p-5 max-h-[75vh] flex-1 flex flex-col justify-center items-center overflow-hidden space-y-6">
									<div className="flex justify-center items-center relative">
										<div className="animate-ping absolute h-full w-full bg-[#ffb099] inset-0 rounded-full" />
										{/* <div className='animate-ping duration-[16s] absolute h-full w-full bg-[#ffd3c7] inset-0 rounded-full z-20' /> */}
										{/* <div className='animate-ping duration-[32s] delay-[16s] absolute h-full w-full bg-[#ffb099] inset-0 rounded-full z-30' /> */}
										<Avatar
											className={classes.large}
											src={
												trainer?.profileImageUrl ||
												`https://avatars.dicebear.com/api/initials/${trainer?.name}.svg`
											}
											alt={trainer?.name}
										/>
									</div>
									<div className="w-full">
										<p className="w-full m-0 flex items-center justify-center">
											Are you understanding my lecture?
										</p>
										<div className="w-full flex items-center">
											{/* <p className='w-5 h-5 m-0 mr-5 flex items-center'>
												No
											</p> */}
											<BoringSlider
												valueLabelDisplay="auto"
												aria-label="boring slider"
												defaultValue={0}
												value={
													currentMood
														? currentMood
														: parseInt(
																avgRating.toFixed(
																	2
																)
														  )
												}
												onChangeCommitted={(
													e,
													value
												) => {
													setRating({
														rating: value,
														roomId:
															boringMeterRoomId ||
															classCode,
													})
													setTimeout(() => {
														setCurrentMood(0)
														setMeterVisible(false)
													}, 1000)
													setMeterThanksVisible(true)
												}}
												onChange={(e, value) =>
													setCurrentMood(value)
												}
												valueLabelFormat={
													currentMood
														? `${currentMood}`
														: `${parseInt(
																avgRating
														  )}`
												}
											/>
											{/* <p className='w-5 h-5 m-0 ml-5 flex items-center'>
												Yes
											</p> */}
										</div>
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default ShowBoringMeter
