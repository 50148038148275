import { useEffect } from "react"

const useHotkeys = actions => {
	const hotKeyHandler = e => {
		if (e.ctrlKey) {
			if (e.key === "d") {
				e.preventDefault()
				actions.toggleMic()
				return
			}
			if (e.key === "e") {
				e.preventDefault()
				actions.toggleCamera()
				return
			}
		}
	}

	useEffect(() => {
		document.addEventListener("keydown", hotKeyHandler)
		return () => document.removeEventListener("keydown", hotKeyHandler)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return null
}

export default useHotkeys
