import { Loader } from "components"
import { useAppContext } from "context"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import axios from "utils/axios"
import { closeClass } from "utils/closeClass"
import {
	useBoringMeterEmitter,
	useChatEmitter,
	useMeetingEmitter,
	usePasteBinEmitter,
	useSessionEmitter,
} from "utils/Emits"
import { version } from "../../package.json"

const Join = () => {
	const {
		user,
		trainer,
		userInteracted,
		meetingId,
		socketState,
		socketPoints,
		hasEnded,
		setValue,
	} = useAppContext()
	const [readyToJoin, setReadyToJoin] = useState(false)
	const { joinMeetingSocket } = useMeetingEmitter()
	const { joinChatSocket } = useChatEmitter()
	const { joinBoringMeterSocket } = useBoringMeterEmitter()
	const { joinPasteBinSocket } = usePasteBinEmitter()
	const { joinSessionSocket } = useSessionEmitter()
	const history = useHistory()
	const { search } = useLocation()
	const sockets = {
		meetingSocket: {
			socket: socketPoints.meetingSocket,
			join: joinMeetingSocket,
		},
		chatSocket: { socket: socketPoints.chatSocket, join: joinChatSocket },
		boringMeterSocket: {
			socket: socketPoints.boringMeterSocket,
			join: joinBoringMeterSocket,
		},
		pasteBinSocket: {
			socket: socketPoints.pasteBinSocket,
			join: joinPasteBinSocket,
		},
		sessionSocket: {
			socket: socketPoints.sessionSocket,
			join: joinSessionSocket,
		},
	}

	useEffect(() => {
		localStorage.setItem("plyrPlaying", "false")
		if (userInteracted) closeClass("endClass")
		setTimeout(() => setReadyToJoin(true), 1000)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleInteraction = () => {
		// Object.keys(sockets).forEach((socket) => sockets[socket].join());
		const socketPending = Object.keys(socketState.connection).filter(
			socket => socketState.connection[socket] === false
		)

		console.debug("@socketPending", socketPending)

		if (socketPending.length) {
			socketPending.every(socket => {
				console.debug(
					`${socket} connection status: ${sockets[socket].socket.connected}`
				)
				if (!sockets[socket].socket.connected) {
					closeClass(`${socket}Error`)
					return false
				}
				sockets[socket].join()
				return true
			})
		}

		if (!hasEnded || user.role === "participant") {
			axios
				.post(`/v2.5/lms/classes/dyte/meeting/${meetingId}/add`)
				.then(res => {
					setValue(prev => ({
						...prev,
						userInteracted: true,
						authToken: res.data.results.data.authToken,
					}))
					history.push(`/${search}`)
				})
				.catch(e => console.error(e))
		} else {
			setValue(prev => ({
				...prev,
				userInteracted: true,
			}))
			history.push(`/${search}`)
		}
	}

	return !userInteracted ? (
		<div className="h-screen w-screen flex flex-col justify-center items-center bg-gray-100 bg-graphics relative">
			<div className="max-w-4xl mx-2 md:mx-0 flex flex-col space-y-6 p-6">
				<div className="relative flex flex-col justify-center items-center space-y-6">
					<div className="flex justify-center items-center relative">
						<div className="animate-ping absolute h-full w-full bg-[#ffb099] inset-0 rounded-full" />
						<img
							src={
								trainer?.profileImageUrl
									? trainer?.profileImageUrl
									: `https://avatars.dicebear.com/api/initials/${encodeURIComponent(
											trainer?.name
									  )}.svg`
							}
							alt={trainer?.name}
							className="w-20 square rounded-full z-50"
						/>
					</div>
					<span className="text-center text-gray-500 space-x-1">
						<span>Connecting to</span>
						<span className="font-semibold text-gray-900">
							{trainer.name}'s
						</span>
						<span>Class</span>
						<span>
							<span className="animate-blink">.</span>
							<span className="animate-blink animation-delay-200">
								.
							</span>
							<span className="animate-blink animation-delay-400">
								.
							</span>
						</span>
					</span>
				</div>
				<div className="flex justify-center">
					{readyToJoin ? (
						<button
							type="button"
							className="w-full text-center px-6 py-3 shadow border border-transparent text-base font-medium rounded-md text-gray-100 bg-gray-900 focus:outline-none focus:ring-0 cursor-pointer"
							onClick={handleInteraction}
						>
							Join Class
						</button>
					) : (
						<Loader />
					)}
				</div>
			</div>
			<div className="space-x-1 md:absolute md:bottom-5 p-4 flex text-gray-400 text-lg">
				<span>Powered by</span>
				<img
					src="/assets/img/lu_icon.png"
					alt=""
					className="h-7 rounded-full square inline-block"
				/>
				<span className="text-purple-700 font-semibold leading-normal">
					LisA
				</span>
				<span className="h-auto px-1 flex items-center justify-center py-0.5 rounded text-xs font-medium bg-pink-100 text-pink-800">
					<span>Beta</span>
				</span>
				<span className="leading-normal">v{version}</span>
			</div>
		</div>
	) : (
		<div className="h-screen w-screen bg-white"></div>
	)
}

export default Join
