import { useAppContext } from "context"
import React, { useEffect } from "react"
import { closeClass } from "utils/closeClass"
import { usePasteBinEmitter } from "utils/Emits"

const PasteBinEvents = () => {
	const { socketPoints, setValue } = useAppContext()
	const { joinPasteBinSocket } = usePasteBinEmitter()

	const pasteBinSocketConnect = () => {
		// joinPasteBinSocket();
		console.debug("@pasteBinSocket Connected")
	}

	// const pasteBinSocketConnected = () => {
	// 	console.debug("@pasteBinSocket Connected via custom");
	// 	joinPasteBinSocket();
	// });

	const pasteBinSocketIoReconnect = attempt => {
		console.debug("@pasteBinSocket reconnect", attempt)
		joinPasteBinSocket()
	}

	const pasteBinSocketIoReconnectAttempt = attempt => {
		console.debug("@pasteBinSocket reconnect_attempt", attempt)
	}
	const pasteBinSocketIoReconnectError = error => {
		console.debug("@pasteBinSocket reconnect_error", error.message)
	}
	const pasteBinSocketIoReconnectFailed = () => {
		console.debug("@pasteBinSocket reconnect_failed")
		setValue(state => ({
			...state,
			socketError: true,
		}))
		closeClass("pasteBinSocketError")
	}

	const pasteBinSocketDisconnect = reason => {
		console.debug("@pasteBinSocket Disconnected: ", reason)
		setValue(state => ({
			...state,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					pasteBinSocket: true,
				},
				error: { ...state.socketState.error, pasteBinSocket: true },
			},
		}))
	}

	const pasteBinSocketError = data => {
		console.error("@pasteBinSocket ERROR: ", data)
		setValue(state => ({
			...state,
			socketError: true,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					pasteBinSocket: false,
				},
				error: { ...state.socketState.error, pasteBinSocket: false },
			},
		}))
		closeClass("pasteBinSocketError")
	}

	const pasteBinSocketJoined = data => {
		console.debug("@pasteBinSocket Joined: ", data)
		setValue(state => ({
			...state,
			pasteBinRoomId: data.roomId,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					pasteBinSocket: true,
				},
				error: { ...state.socketState.error, pasteBinSocket: false },
			},
		}))
	}

	const pasteBinSocketResource = ({ message: resource }) => {
		console.debug("@pasteBinSocket.on('resource'):", resource)
		const notifier = new Audio("/assets/audio/ding.mp3")
		notifier.crossOrigin = "anonymous"
		notifier.addEventListener("canplaythrough", () => {
			notifier.play()
			console.debug("notified")
		})
		setValue(state => ({
			...state,
			resources: [
				{ ...resource, createdAt: new Date().toISOString() },
				...state.resources,
			],
		}))
		const resourceContainer = document.getElementById("resourceContainer")
		resourceContainer &&
			resourceContainer.scrollTo({
				top: resourceContainer.scrollHeight,
				behavior: "smooth",
			})
	}

	const pasteBinSocketVisible = ({ _id, isVisible }) => {
		console.debug("@pasteBinSocket.on('visible'):", { _id, isVisible })
		setValue(state => ({
			...state,
			resources: state.resources.map(r => {
				if (r._id === _id) r.isVisible = isVisible
				return r
			}),
		}))
	}

	const pasteBinSocketAddAgenda = agenda => {
		console.debug("@pasteBinSocket.on('addAgenda'):", agenda)
		setValue(state => ({
			...state,
			agendas: [...state.agendas, agenda],
		}))
	}

	const pasteBinSocketUpdateAgenda = agenda => {
		console.debug("@pasteBinSocket.on('updateAgenda'):", agenda)
		setValue(state => ({
			...state,
			agendas: state.agendas.map(item =>
				item.id === agenda.id ? agenda : item
			),
		}))
	}

	const pasteBinSocketDeleteAgenda = agenda => {
		console.debug("@pasteBinSocket.on('deleteAgenda'):", agenda)
		setValue(state => ({
			...state,
			agendas: state.agendas.filter(item => item.id !== agenda.id),
		}))
	}

	useEffect(() => {
		socketPoints.pasteBinSocket.on("connect", pasteBinSocketConnect)
		// socketPoints.pasteBinSocket.on("connected", pasteBinSocketConnected);
		socketPoints.pasteBinSocket.io.on(
			"reconnect",
			pasteBinSocketIoReconnect
		)
		socketPoints.pasteBinSocket.io.on(
			"reconnect_attempt",
			pasteBinSocketIoReconnectAttempt
		)
		socketPoints.pasteBinSocket.io.on(
			"reconnect_error",
			pasteBinSocketIoReconnectError
		)
		socketPoints.pasteBinSocket.io.on(
			"reconnect_failed",
			pasteBinSocketIoReconnectFailed
		)
		socketPoints.pasteBinSocket.on("disconnect", pasteBinSocketDisconnect)
		socketPoints.pasteBinSocket.on("error", pasteBinSocketError)
		socketPoints.pasteBinSocket.on("joined", pasteBinSocketJoined)
		socketPoints.pasteBinSocket.on("resource", pasteBinSocketResource)
		socketPoints.pasteBinSocket.on("visible", pasteBinSocketVisible)
		socketPoints.pasteBinSocket.on("addAgenda", pasteBinSocketAddAgenda)
		socketPoints.pasteBinSocket.on(
			"updateAgenda",
			pasteBinSocketUpdateAgenda
		)
		socketPoints.pasteBinSocket.on(
			"deleteAgenda",
			pasteBinSocketDeleteAgenda
		)

		return () => {
			socketPoints.pasteBinSocket.off("connect", pasteBinSocketConnect)
			// socketPoints.pasteBinSocket.off("connected", pasteBinSocketConnected);
			socketPoints.pasteBinSocket.io.off(
				"reconnect",
				pasteBinSocketIoReconnect
			)
			socketPoints.pasteBinSocket.io.off(
				"reconnect_attempt",
				pasteBinSocketIoReconnectAttempt
			)
			socketPoints.pasteBinSocket.io.off(
				"reconnect_error",
				pasteBinSocketIoReconnectError
			)
			socketPoints.pasteBinSocket.io.off(
				"reconnect_failed",
				pasteBinSocketIoReconnectFailed
			)
			socketPoints.pasteBinSocket.off(
				"disconnect",
				pasteBinSocketDisconnect
			)
			socketPoints.pasteBinSocket.off("error", pasteBinSocketError)
			socketPoints.pasteBinSocket.off("joined", pasteBinSocketJoined)
			socketPoints.pasteBinSocket.off("resource", pasteBinSocketResource)
			socketPoints.pasteBinSocket.off("visible", pasteBinSocketVisible)
			socketPoints.pasteBinSocket.off(
				"addAgenda",
				pasteBinSocketAddAgenda
			)
			socketPoints.pasteBinSocket.off(
				"updateAgenda",
				pasteBinSocketUpdateAgenda
			)
			socketPoints.pasteBinSocket.off(
				"deleteAgenda",
				pasteBinSocketDeleteAgenda
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <></>
}

export default PasteBinEvents
