import { Tab } from "@headlessui/react"
import {
	CalendarIcon,
	ChatIcon as ChatOutlineIcon,
	ChevronUpIcon,
	ClipboardListIcon as ClipboardListOutlineIcon,
	ExclamationIcon,
	EyeIcon,
	UsersIcon as UsersOutlineIcon,
} from "@heroicons/react/outline"
import {
	ChatIcon as ChatSolidIcon,
	ClipboardListIcon as ClipboardListSolidIcon,
	UsersIcon as UsersSolidIcon,
} from "@heroicons/react/solid"
import { Typography } from "@material-ui/core"
import {
	ShowAttendanceFormModal,
	ShowDetailsModal,
	ShowTabsModal,
} from "components/Modals"
import VimeJS from "components/vimejs"
import { useAppContext } from "context"
import { isAfter, isBefore } from "date-fns"
import { useState } from "react"
import classNames from "utils/classNames"
import { version } from "../../../package.json"
import { BackBtn } from "./ControlBtn"
import MeetingView from "./View"

const Meeting = () => {
	const {
		classTitle,
		participants,
		classDate,
		attendanceDeadline,
		user,
		attended,
	} = useAppContext()
	const [showDetails, setShowDetails] = useState(false)
	const [showTabs, setShowTabs] = useState(false)
	const [activeTab, setActiveTab] = useState(0)
	const [showAttendanceForm, setShowAttendanceForm] = useState(false)

	const closeAttendanceForm = () => setShowAttendanceForm(false)

	const closeTabs = () => {
		setShowTabs(false)
		setActiveTab(-1)
	}

	// useEffect(
	// 	() =>
	// 		provideDyteDesignSystem(document.body, {
	// 			colors: {
	// 				brand: {
	// 					300: "#00FFE1",
	// 					400: "#00FFFF",
	// 					500: "#00E1D4",
	// 					600: "#007B74",
	// 					700: "#00655F",
	// 				},
	// 				background: {
	// 					1000: "#FFFFFF",
	// 					900: "#E6E6E6",
	// 					800: "#DADADD",
	// 					700: "#CDCDD0",
	// 					600: "#C0C0C1",
	// 				},
	// 				text: "#071428",
	// 				"video-bg": "#E5E7EB",
	// 			},
	// 		}),
	// 	[]
	// );

	return (
		<>
			<div className="flex h-screen flex-col">
				<div className="px-4 md:py-4 py-0 flex flex-wrap items-center justify-evenly bg-white">
					<div className="flex items-center justify-between flex-grow space-x-3">
						<div className="flex items-center flex-grow space-x-3">
							{showTabs ? null : (
								<BackBtn className="text-gray-700 w-min" />
							)}
							<Typography
								variant="h6"
								className="flex-grow hidden md:block"
							>
								{classTitle}
							</Typography>
						</div>
						<button
							type="submit"
							className={classNames(
								"inline-flex justify-center p-2 text-sm font-medium border border-transparent rounded-md focus:outline-none focus-visible:ring-0",
								(isAfter(new Date(), new Date(classDate)) &&
									isBefore(
										new Date(),
										new Date(attendanceDeadline)
									)) === false || attended === true
									? "cursor-not-allowed text-blue-200 bg-blue-50"
									: "hover:bg-blue-200 text-blue-900 bg-blue-100"
							)}
							onClick={() => setShowAttendanceForm(true)}
							disabled={
								(isAfter(new Date(), new Date(classDate)) &&
									isBefore(
										new Date(),
										new Date(attendanceDeadline)
									)) === false || attended === true
							}
						>
							Mark Attendance
						</button>
						<div className="space-x-1 py-4 px-2 flex text-gray-400 text-xs">
							<img
								src="/assets/img/lu_icon.png"
								alt=""
								className="h-5 rounded-full square inline-block"
							/>
							<span className="text-purple-700 font-semibold leading-normal">
								LisA
							</span>
							<span className="h-auto px-1 py-0.5 rounded text-[8px] font-medium bg-pink-100 text-pink-800">
								Beta
							</span>
							<span className="leading-normal">v{version}</span>
						</div>
						<span className="md:flex space-x-4 hidden">
							<div className="py-1 px-2 flex items-center space-x-2 text-gray-500">
								<EyeIcon className="h-6 w-6" />
								<Typography
									className="text-gray-500"
									variant="h6"
								>
									{participants.length}
								</Typography>
							</div>
						</span>
					</div>
				</div>
				<div className="bg-yellow-100 md:hidden flex items-center justify-center space-x-2 py-px">
					<ExclamationIcon className="w-4 h-4 animate-wiggle -mb-px" />
					<span className="text-xs text-yellow-900">
						Some features are restricted to Desktop
					</span>
				</div>
				{user.role.toLowerCase() === "participant" ? (
					<MeetingView />
				) : (
					<VimeJS />
				)}
				<div className="flex flex-col items-start bg-white hover:bg-gray-50 cursor-pointer divide-y divide-gray-200">
					<div
						className="px-4 py-3 w-full md:hidden flex justify-between items-center border-t-[1px]"
						onClick={() => setShowDetails(true)}
					>
						<div className="flex flex-col space-y-1">
							<span className="text-base font-medium flex-grow text-gray-900 line-clamp-2">
								{classTitle}
							</span>
							<div className="flex space-x-5">
								<div className="flex items-center space-x-2 text-gray-500">
									<EyeIcon className="h-4 w-4" />
									<span className="text-sm text-gray-500">
										{participants.length}
									</span>
								</div>
								<div className="flex items-center space-x-2 text-gray-500">
									<CalendarIcon className="h-4 w-4" />
									<span className="text-sm text-gray-500">
										{new Date(classDate).toDateString()}
									</span>
								</div>
							</div>
						</div>
						<ChevronUpIcon className="h-4 w-4" />
					</div>
					<div
						className="w-full md:hidden"
						onClick={() => (showTabs ? null : setShowTabs(true))}
					>
						<Tab.Group
							selectedIndex={activeTab}
							onChange={setActiveTab}
						>
							<Tab.List className="w-full flex space-x-1 bg-white">
								<Tab
									className={({ selected }) =>
										classNames(
											"w-full flex flex-col justify-center items-center space-y-1 px-2.5 py-2 text-sm font-medium leading-5 transition-all duration-200 bg-white focus:ring-0 focus-visible:outline-none",
											showTabs && selected
												? "text-lu"
												: "text-gray-900"
										)
									}
								>
									{showTabs && activeTab === 0 ? (
										<ClipboardListSolidIcon className="w-6 h-6" />
									) : (
										<ClipboardListOutlineIcon className="w-6 h-6" />
									)}
									<span className="text-xs">Agenda</span>
								</Tab>
								<Tab
									className={({ selected }) =>
										classNames(
											"w-full flex flex-col justify-center items-center space-y-1 px-2.5 py-2 text-sm font-medium leading-5 transition-all duration-200 bg-white focus:ring-0 focus-visible:outline-none",
											showTabs && selected
												? "text-lu"
												: "text-gray-900"
										)
									}
								>
									{showTabs && activeTab === 1 ? (
										<ChatSolidIcon className="w-6 h-6" />
									) : (
										<ChatOutlineIcon className="w-6 h-6" />
									)}
									<span className="text-xs">Chat</span>
								</Tab>
								<Tab
									className={({ selected }) =>
										classNames(
											"w-full flex flex-col justify-center items-center space-y-1 px-2.5 py-2 text-sm font-medium leading-5 transition-all duration-200 bg-white focus:ring-0 focus-visible:outline-none",
											showTabs && selected
												? "text-lu"
												: "text-gray-900"
										)
									}
								>
									{showTabs && activeTab === 2 ? (
										<UsersSolidIcon className="w-6 h-6" />
									) : (
										<UsersOutlineIcon className="w-6 h-6" />
									)}
									<span className="text-xs">Upgraders</span>
								</Tab>
							</Tab.List>
						</Tab.Group>
					</div>
				</div>
			</div>

			<ShowDetailsModal
				showDetails={showDetails}
				closeDetails={() => setShowDetails(false)}
			/>
			<ShowTabsModal
				showTabs={showTabs}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				closeTabs={closeTabs}
			/>
			<ShowAttendanceFormModal
				showAttendanceForm={showAttendanceForm}
				closeAttendanceForm={closeAttendanceForm}
			/>
		</>
	)
}

export default Meeting
