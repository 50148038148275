import { Typography } from "@material-ui/core"
import WarningIcon from "@material-ui/icons/Warning"
import { Loader } from "components"
import Cookies from "js-cookie"
import { createContext, useContext, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { UAParser } from "ua-parser-js"
import { connectSockets } from "utils"
import axios from "utils/axios"
import { closeClass } from "utils/closeClass"
import CookieService from "utils/cookie.service"
import groupifyChat from "utils/groupifyChat"

const initialValue = {
	userInteracted: false,
	chatRoomId: "",
	boringMeterRoomId: "",
	pasteBinRoomId: "",
	meetingId: null,
	authToken: null,
	agendas: [],
	chats: [],
	chatPage: 1,
	participants: [],
	resources: [],
	resourcesPage: 1,
	doubts: [],
	doubtsPage: 1,
	avgRating: 0,
	user: {},
	token: "",
	videoTimeStamp: 0,
	programTitle: "",
	classTitle: "",
	classDesc: "",
	videoDetails: {},
	videoId: "",
	downstreamUrl: "",
	recordingUrl: "",
	roomJoined: false,
	isLive: false,
	takeFeedback: false,
	sideBarOpen: true,
	role: "Student",
	onFeedBackPage: false,
	trainer: null,
	feedbackTimer: 0,
	timeSpent: 0,
	videoIsPlaying: false,
	socketPoints: {
		meetingSocket: null,
		chatSocket: null,
		boringMeterSocket: null,
		pasteBinSocket: null,
		sessionSocket: null,
	},
	socketState: {
		connection: {
			meetingSocket: false,
			chatSocket: false,
			boringMeterSocket: false,
			pasteBinSocket: false,
			sessionSocket: false,
		},
		error: {
			meetingSocket: false,
			chatSocket: false,
			boringMeterSocket: false,
			pasteBinSocket: false,
			sessionSocket: false,
		},
	},
	unread: {
		chat: 0,
		doubts: 0,
		resources: 0,
	},
}

export const AppContext = createContext(initialValue)

export const useAppContext = () => useContext(AppContext)

const cookieOptions = {
	domain:
		process.env.NODE_ENV === "development"
			? "localhost"
			: `.letsupgrade.in`,
	path: "/",
}

const AppContextProvider = ({ children }) => {
	const [value, setValue] = useState(initialValue)
	const [waitingForTokens, setWaitingForTokens] = useState(true)
	const [isLoading, setIsLoading] = useState(true)
	const [hasErrored, setHasErrored] = useState(false)
	const [error, setError] = useState("Oops! Something Went Wrong")
	const uaParser = new UAParser()
	const os = uaParser.getOS()
	const history = useHistory()
	const { pathname, search } = useLocation()

	// const receiveMessage = (event) => {
	// 	if (event.data.from === "LisA") {
	// 		console.debug("@receiveMessage: ", event.data);
	// 		CookieService.setTokens({
	// 			accessToken: event.data.accessToken,
	// 			refreshToken: event.data.refreshToken,
	// 		});
	// 		setWaitingForTokens(false);
	// 	}
	// };

	const reInventCycle = () => {
		const programCode = window.localStorage.getItem("programCode")
		const urls = {
			production: `http://letsupgrade.in/my-programs/${programCode}`,
			development: `http://localhost:3333/my-programs/${programCode}`,
		}
		window.open(urls[process.env.NODE_ENV], "_blank")
		window.close()
	}

	useEffect(() => {
		window.Cookies = Cookies
		window.CookieService = CookieService
		window.closeClass = () => closeClass("oops")
		if (new URLSearchParams(window.location.search).get("jwt")) {
			setWaitingForTokens(false)
			return
		}
		setWaitingForTokens(true)
		const accessToken = Cookies.get("lupower", cookieOptions)
		const refreshToken = Cookies.get("lupower_refresh", cookieOptions)

		console.debug("Found", {
			accessToken: accessToken,
			refreshToken,
		})

		if (window.opener && accessToken && refreshToken) {
			// window.opener.postMessage("sendCookies", "*");
			// window.addEventListener("message", receiveMessage);
			// return () => window.removeEventListener("message", receiveMessage);
			CookieService.setTokens({
				accessToken,
				refreshToken,
			})
			setWaitingForTokens(false)
		} else {
			reInventCycle()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (waitingForTokens) return
		setIsLoading(true)
		setHasErrored(false)
		try {
			const token =
				new URLSearchParams(window.location.search).get("jwt") ||
				CookieService.getLocalAccessToken()
			const programCode = new URLSearchParams(window.location.search).get(
				"programCode"
			)
			const classCode = new URLSearchParams(window.location.search).get(
				"classCode"
			)
			if (!token || !programCode || !classCode) {
				const missing = []
				if (!token) missing.push("token")
				if (!programCode) missing.push("programCode")
				if (!classCode) missing.push("classCode")
				closeClass("missingParameter")
				setError(`Missing Parameter: ${missing.join(" ")}`)
				throw new Error(`Missing Parameter: ${missing.join(", ")}`)
			}
			CookieService.updateLocalAccessToken(token)
			localStorage.setItem("programCode", programCode)
			localStorage.setItem("classCode", classCode)

			console.debug("Connecting to Sockets.....")
			window.token = token

			const socketPoints = connectSockets(token)
			window.sockets = socketPoints

			setValue(prevState => ({
				...prevState,
				socketPoints,
			}))

			axios
				.get(`/v2/program/check?program=${programCode}`)
				.then(async ({ data }) => {
					if (!data.results.isEligible) {
						setValue(state => ({
							...state,
							isEligible: data.results.isEligible,
						}))
						setIsLoading(false)
						closeClass("notEligible")
						return
					}
					if (pathname !== "/join") {
						console.debug({ pathname, search })
						history.push(`/join${search}`)
					}
					let [
						profile,
						classData,
						members,
						chats,
						resources,
						doubts,
					] = await Promise.all([
						axios.get("/v1/users/me").catch(e => {
							console.error(e)
						}),
						axios
							.get(`/v2/programs/${programCode}/${classCode}`)
							.catch(e => {
								console.error(e)
							}),
						axios.get(`/v1/members/chat/${classCode}`).catch(e => {
							console.error(e)
						}),
						axios({
							method: "GET",
							url: `/v1/chats/${classCode}`,
							params: { page: 1, limit: 10 },
						}).catch(e => {
							console.error(e)
						}),
						axios({
							method: "GET",
							url: `/v1/files/${classCode}`,
							params: { page: 1, limit: 10 },
						}).catch(e => {
							console.error(e)
						}),
						axios({
							method: "GET",
							url: `/v1/doubts/${classCode}`,
							params: { page: 1, limit: 10 },
						}).catch(e => {
							console.error(e)
						}),
					])
					const userProfile = {
						name: profile.data.results.data.name,
						profileImageUrl:
							profile.data.results.data.profileImageUrl,
						uid: profile.data.results.data.uid,
						username: profile.data.results.data.username,
						email: profile.data.results.data.email,
					}
					window.LOQ = window.LOQ || []
					window.LOQ.push([
						"ready",
						async LO => {
							// Or, identify a visitor
							await LO.$internal.ready("visitor")
							LO.visitor.identify(userProfile)
						},
					])
					const {
						agenda: agendas,
						name: classTitle,
						classDetails: classDesc,
						meeting,
						meetingId,
						videoId,
						downstreamUrl,
						recordingUrl,
						isLive,
						type,
						classDate,
						attendanceDeadline,
						seekTime,
						trainer,
						hasEnded,
						attended = false,
						feedbackTimer = 10,
						autoAdmit,
					} = classData.data.results.data
					document.title = `${classTitle} - LetsUpgrade - Lisa ${new Date().getFullYear()}`
					document.querySelector('meta[name="description"]').content =
						classDesc || classTitle.trim()

					const userMisc = {
						handRaiseStatus: null,
						role: "student",
						misc: {
							micReq: false,
							camReq: false,
							chatState: {
								banned: false,
								time: 0,
							},
						},
					}

					const trainerResp = await axios.get(
						`/users/${trainer.username}`
					)

					const trainerProfile = {
						...trainer,
						following: trainerResp.data.results.data.following,
					}

					const videoLink = isLive ? downstreamUrl : recordingUrl
					const videoDetails = videoLink
						? videoLink.includes("m3u8")
							? {
									link: videoLink,
							  }
							: {
									provider: videoLink
										.split(".com")[0]
										.split("https://")
										.at(-1)
										.split(".")
										.at(-1),
									embedId: videoLink.split("/").at(-1),
							  }
						: null
					if (
						videoDetails &&
						videoDetails.provider &&
						videoDetails.provider === "vimeo" &&
						videoDetails.embedId.includes("?h=")
					)
						videoDetails.embedId = `${videoDetails.embedId}&`

					setValue(state => ({
						...state,
						videoTimeStamp: Number(seekTime),
						programCode,
						classCode,
						isEligible: data.results.isEligible,
						attended,
						token,
						user: {
							...userProfile,
							...userMisc,
						},
						participants: [
							...members.data.results.data.map(m => ({
								...m.user,
								following: m.isFollowing,
								handRaiseStatus: null,
								lastDoubtId: null,
								permissions: {
									chat: {
										isBanned: m.isBanned,
										time: m.time,
									},
								},
							})),
						],
						chats: chats.data.results.data.length
							? groupifyChat(
									chats.data.results.data.map(m => ({
										...m,
										isDeleted: false,
									})),
									[]
							  )
							: [],
						chatPage: 1,
						resources: [...resources.data.results.data],
						resourcesPage: 1,
						doubts: [
							...doubts.data.results.data.map(d => ({
								...d,
								name: d.user.name,
								answer: d.solution,
							})),
						],
						doubtsPage: 1,
						agendas,
						type,
						classTitle,
						classDesc: classDesc?.trim(),
						classDate,
						attendanceDeadline,
						videoDetails,
						fallBackLink:
							"https://lucdn.letsupgrade.net/LU_Intro_ec469be0f5.mp4",
						videoType: "main",
						videoId,
						downstreamUrl,
						recordingUrl,
						meetingId: meeting?.meetingId || meetingId || true,
						isLive,
						trainer: trainerProfile,
						feedbackTimer,
						hasEnded,
						os,
						autoAdmit,
					}))
					setIsLoading(false)
				})
				.catch(e => {
					setIsLoading(false)
					closeClass("oops")
					throw new Error(e)
				})
		} catch (e) {
			setIsLoading(false)
			setError(e.message)
			closeClass("oops")
			setHasErrored(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [waitingForTokens])

	useEffect(() => {
		if (value.isEligible && waitingForTokens === false) {
			const { programCode, classCode, socketPoints } = value
			console.debug('all.emit("join")', { programCode, classCode })
			socketPoints.meetingSocket.emit("join", {
				programCode,
				classCode,
			})
			socketPoints.chatSocket.emit("join", {
				programCode,
				classCode,
			})
			socketPoints.boringMeterSocket.emit("join", {
				programCode,
				classCode,
			})
			socketPoints.pasteBinSocket.emit("join", {
				programCode,
				classCode,
			})
			socketPoints.sessionSocket.emit("join", {
				programCode,
				classCode,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.isEligible, waitingForTokens])

	useEffect(() => {
		const check = () => {
			let vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty("--vh", `${vh}px`)
		}
		check()
		window.addEventListener("resize", check)
		return () => window.removeEventListener("resize", check)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const { downstreamUrl, recordingUrl, isLive } = value
		const videoLink = isLive ? downstreamUrl : recordingUrl
		const videoDetails = videoLink
			? videoLink.includes("m3u8")
				? {
						link: videoLink,
				  }
				: {
						provider: videoLink
							.split(".com")[0]
							.split("https://")
							.at(-1)
							.split(".")
							.at(-1),
						embedId: videoLink.split("/").at(-1),
				  }
			: null
		if (
			videoDetails &&
			videoDetails.provider &&
			videoDetails.provider === "vimeo" &&
			videoDetails.embedId.includes("?h=")
		)
			videoDetails.embedId = `${videoDetails.embedId}&`
		setValue(state => ({
			...state,
			videoDetails,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.isLive])

	useEffect(() => {
		if (value.autoAdmit) {
			axios
				.post(`/v2.5/lms/classes/dyte/meeting/${value.meetingId}/add`)
				.then(res => {
					setValue(prev => ({
						...prev,
						authToken: res.data.results.data.authToken,
						hasEnded: false,
						user: {
							...prev.user,
							handRaiseStatus: "pending",
							role: "participant",
						},
					}))
				})
				.catch(e => {
					console.error(e)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.autoAdmit, value.meetingId])

	useEffect(() => {
		window.value = value
		if (
			process.env.NODE_ENV === "development" ||
			window?.canGetMore === "YES"
		) {
			window.addSelf = () => {
				axios
					.post(
						`/v2.5/lms/classes/dyte/meeting/${value.meetingId}/add`
					)
					.then(res => {
						setValue(prev => ({
							...prev,
							userInteracted: true,
							authToken: res.data.results.data.authToken,
							hasEnded: false,
							user: {
								...prev.user,
								role: "participant",
								handRaiseStatus: "pending",
							},
						}))
					})
					.catch(e => {
						console.error(e)
					})
			}
			window.seeLive = () =>
				setValue(prev => ({ ...prev, isLive: !prev.isLive }))
		}
	}, [value])

	return (
		<AppContext.Provider value={{ ...value, setValue }}>
			{waitingForTokens ? (
				<div className="h-screen w-screen bg-white flex flex-col items-center justify-center">
					<Loader text="Waiting For Communication from server" />
				</div>
			) : isLoading ? (
				<div className="h-screen w-screen bg-white flex flex-col items-center justify-center">
					<Loader text="Please wait" />
				</div>
			) : hasErrored ? (
				<div className="h-screen w-screen flex items-center justify-center">
					<div className="flex flex-col items-center justify-center">
						<WarningIcon
							color="error"
							fontSize="large"
						/>
						<Typography>{error}</Typography>
					</div>
				</div>
			) : value.isEligible ? (
				children
			) : (
				<div className="h-screen w-screen flex items-center justify-center">
					<div className="flex flex-col items-center justify-center">
						<WarningIcon
							color="error"
							fontSize="large"
						/>
						<Typography>
							You are not Eligible for this class
						</Typography>
					</div>
				</div>
			)}
		</AppContext.Provider>
	)
}
export default AppContextProvider
