import { LinkPreview } from "@dhaiwat10/react-link-preview"
import { Dialog, Transition } from "@headlessui/react"
import { DownloadIcon } from "@heroicons/react/outline"
import { CheckIcon, ClipboardCopyIcon, XIcon } from "@heroicons/react/solid"
import { Typography } from "@material-ui/core"
import WarningIcon from "@material-ui/icons/Warning"
import { Loader } from "components"
import ContextMenu from "components/ContextMenu"
import { useAppContext } from "context"
import { formatDistanceToNow } from "date-fns"
import { Fragment, useCallback, useRef, useState } from "react"
import { defaultStyles, FileIcon } from "react-file-icon"
import Slide from "react-reveal/Slide"
import classNames from "utils/classNames"
import { usePasteBinEmitter } from "utils/Emits"
import useGetPasteBin from "utils/useGetPasteBin"

const downloadFile = msg => {
	const link = document.createElement("a")
	link.href = msg.link
	link.setAttribute("download", msg.name)
	link.setAttribute("target", "_blank")
	link.setAttribute("noreferrer", true)
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

// eslint-disable-next-line no-unused-vars
const Image = ({ resource, handleToggleVisibility }) => {
	const [showPreview, setShowPreview] = useState(false)
	const [resourceToShow, setResourceToShow] = useState({})

	const closePreview = () => {
		setShowPreview(false)
	}
	return (
		<>
			<div className="flex justify-center space-x-2">
				<div className="flex content-center justify-center min-w-[25%] max-w-[75%]">
					<img
						src={
							resource.ext === "svg"
								? "https://cdn2.iconfinder.com/data/icons/business-and-marketing-17/64/Image-512.png"
								: resource.link
						}
						alt={
							resource.ext === "svg"
								? "Could Not Load Resource"
								: resource.name
						}
						title={
							resource.ext === "svg"
								? "Could Not Load Resource"
								: resource.name
						}
						className={classNames(
							"rounded-lg",
							resource.ext === "svg" && "w-10"
						)}
						onClick={() => {
							if (resource.ext !== "svg") {
								setResourceToShow(resource)
								setShowPreview(true)
							}
						}}
					/>
				</div>
				<Typography
					className="break-words"
					dangerouslySetInnerHTML={{
						__html: resource.text,
					}}
				/>
			</div>
			<Transition
				appear
				show={showPreview}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closePreview}
				>
					<div className="max-h-screen max-h-screen-ios px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen h-screen-ios align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-flex flex-col max-w-3xl min-w-[50vw] min-h-[50vh] max-h-[75vh] p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
								<div className="flex justify-between items-center">
									<Dialog.Title
										as="h3"
										className="text-lg font-medium leading-6 text-gray-900"
									>
										{resourceToShow.name}
									</Dialog.Title>
									<div className="flex space-x-4">
										<label className="relative flex justify-end items-center text-xl">
											<input
												type="checkbox"
												className="absolute left-1/2 -translate-x-1/2 w-full h-full peer hidden rounded-md"
												checked={
													resourceToShow.isVisible
												}
												onChange={() =>
													resource._id &&
													handleToggleVisibility(
														resourceToShow
													)
												}
											/>
											<span
												className={classNames(
													"w-8 h-4 after:w-2 after:h-2 peer-checked:after:translate-x-4 flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-gray-900 after:bg-white after:rounded-full after:shadow-md after:duration-300",
													resource._id
														? "cursor-pointer"
														: "cursor-not-allowed"
												)}
											/>
										</label>
										<div
											className="inline-flex justify-center p-2 text-sm font-medium text-white bg-green-500 hover:bg-green-600 rounded-lg"
											onClick={() =>
												downloadFile(resourceToShow)
											}
										>
											<DownloadIcon className="w-4 h-4" />
										</div>
										<button
											type="button"
											className="inline-flex justify-center p-2 text-sm font-medium text-black hover:bg-gray-100 rounded-lg"
											onClick={closePreview}
										>
											<XIcon className="h-4 w-4" />
										</button>
									</div>
								</div>
								<div className="mt-2 p-5 max-h-[75vh] flex-1 flex justify-center items-center overflow-hidden">
									<img
										src={resourceToShow.link}
										alt={resourceToShow.link}
									/>
								</div>
								{resourceToShow.createdAt && (
									<time
										dateTime={resourceToShow.createdAt}
										className="w-full flex-shrink-0 whitespace-nowrap text-xs text-gray-400"
									>
										{formatDistanceToNow(
											new Date(resourceToShow.createdAt),
											{
												addSuffix: true,
												includeSeconds: true,
											}
										)}
									</time>
								)}
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

const File = ({
	resource,
	handleToggleVisibility,
	showPreview,
	setShowPreview,
}) => {
	const [resourceToShow, setResourceToShow] = useState({})

	const closePreview = () => {
		setShowPreview(false)
	}
	return (
		<>
			<div className="w-full flex flex-col space-y-4">
				<div
					className="flex w-full overflow-hidden space-x-2"
					onClick={() => {
						setResourceToShow(resource)
						setShowPreview(true)
					}}
				>
					<div className="flex content-center justify-center h-10 square">
						<FileIcon
							extension={resource.ext}
							{...defaultStyles[resource.ext]}
						/>
					</div>
					<div className="min-w-0 flex flex-col flex-1">
						<span className="text-sm font-medium truncate text-gray-900 whitespace-pre-line">
							{resource.name ||
								resource.link
									.split("/")
									.at(-1)
									.split("_")
									.at(-1)}
						</span>
						{/* <span className='flex space-x-2'> */}
						<time
							dateTime={resource.createdAt}
							className="flex-shrink-0 whitespace-nowrap text-xs text-gray-400"
						>
							{formatDistanceToNow(new Date(resource.createdAt), {
								addSuffix: true,
								includeSeconds: true,
							})}
						</time>
					</div>
				</div>
				{resource.text && (
					<Typography
						className="break-words"
						dangerouslySetInnerHTML={{
							__html:
								resource.name ||
								resource.link
									.split("/")
									.at(-1)
									.split("_")
									.at(-1),
						}}
					/>
				)}
			</div>
			<Transition
				appear
				show={showPreview}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closePreview}
				>
					<div className="max-h-screen max-h-screen-ios px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen h-screen-ios align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-flex flex-col max-w-3xl min-w-[50vw] min-h-[50vh] max-h-[75vh] p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
								<div className="flex justify-between items-center">
									<Dialog.Title
										as="h3"
										className="text-lg font-medium leading-6 text-gray-900"
									>
										{resourceToShow.name}
									</Dialog.Title>
									<div className="flex space-x-4">
										<div
											className="inline-flex justify-center p-2 text-sm font-medium text-white bg-green-500 hover:bg-green-600 rounded-lg"
											onClick={() =>
												downloadFile(resourceToShow)
											}
										>
											<DownloadIcon className="w-4 h-4" />
										</div>
										<button
											type="button"
											className="inline-flex justify-center p-2 text-sm font-medium text-black hover:bg-gray-100 rounded-lg"
											onClick={closePreview}
										>
											<XIcon className="h-4 w-4" />
										</button>
									</div>
								</div>
								<div className="mt-2 p-5 max-h-[75vh] flex-1 flex justify-center items-center overflow-hidden">
									{resourceToShow.isImage &&
									resourceToShow.ext !== "svg" ? (
										<img
											src={resourceToShow.link}
											alt={resourceToShow.link}
											className="max-w-[80%] imgPreview"
										/>
									) : (
										<div className="flex flex-col items-center space-y-4">
											{/* <img
												src='https://cdn2.iconfinder.com/data/icons/business-and-marketing-17/64/Image-512.png'
												alt='No Preview'
												className='w-1/2'
											/> */}
											<span className="text-lg font-semibold">
												No Preview Available
											</span>
											<div
												className="inline-flex items-center justify-center p-2 text-sm font-medium text-white bg-green-500 hover:bg-green-600 rounded-lg space-x-2"
												onClick={() =>
													downloadFile(resourceToShow)
												}
											>
												<DownloadIcon className="w-6 h-6" />
												<span className="text-lg font-semibold">
													Download
												</span>
											</div>
										</div>
									)}
								</div>
								{resourceToShow.createdAt && (
									<time
										dateTime={resourceToShow.createdAt}
										className="w-full flex-shrink-0 whitespace-nowrap text-xs text-gray-400"
									>
										{formatDistanceToNow(
											new Date(resourceToShow.createdAt),
											{
												addSuffix: true,
												includeSeconds: true,
											}
										)}
									</time>
								)}
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

const Resource = ({ resource }) => {
	const [showPreview, setShowPreview] = useState(false)
	const [copied, setCopied] = useState(0)
	const { classCode, pasteBinRoomId } = useAppContext()
	const { toggleVisibility } = usePasteBinEmitter()

	const menuOptions = [
		{
			label: "Download",
			icon: DownloadIcon,
			action: ({ e, msg }) => {
				console.debug("Download", msg.name)
				downloadFile(msg)
			},
		},
	]

	const handleToggleVisibility = resource => {
		toggleVisibility({
			_id: resource._id,
			roomId: pasteBinRoomId || classCode,
			isVisible: !resource.isVisible,
		})
	}

	const handleCopy = resource => {
		setCopied(1)
		navigator.clipboard.writeText(resource.text).then(
			function () {
				setCopied(2)
				setTimeout(() => setCopied(0), 750)
			},
			function (err) {
				console.error("Async: Could not copy text: ", err)
				setCopied(0)
			}
		)
	}

	return (
		<>
			<ContextMenu
				msg={resource}
				menuOptions={menuOptions}
				showPreview={showPreview}
				className="relative h-full w-full bg-white group hover:bg-gray-100"
			>
				{resource.text && !resource.text.includes('target="_blank"') ? (
					<span
						className="absolute top-1 right-1 p-1 bg-white rounded-md border shadow group-hover:block hidden z-10"
						onClick={() => handleCopy(resource)}
					>
						{copied === 0 ? (
							<ClipboardCopyIcon className="h-5 w-5 text-gray-700" />
						) : copied === 1 ? (
							<svg
								fill="none"
								viewBox="0 0 24 24"
								className="w-5 h-5 animate-spin"
							>
								<circle
									className="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="#ff66005e"
									strokeWidth="4"
								/>
								<path
									fill="#ff6600"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								/>
							</svg>
						) : (
							<CheckIcon className="h-5 w-5 text-green-500" />
						)}
					</span>
				) : null}
				<div className="flex flex-col px-4 py-4 sm:px-6 overflow-hidden space-y-2 divide-y divide-gray-200">
					<div className="flex items-center justify-evenly space-x-2 py-2">
						{resource.source === "file" ? (
							// resource.isImage ? (
							// 	<Image
							// 		resource={resource}
							// 		handleToggleVisibility={
							// 			handleToggleVisibility
							// 		}
							// 	/>
							// ) : (
							<File
								resource={resource}
								handleToggleVisibility={handleToggleVisibility}
								showPreview={showPreview}
								setShowPreview={setShowPreview}
							/>
						) : (
							// )
							resource.source === "input" && (
								<div className="w-full">
									{resource.link.length ? (
										<LinkPreview
											url={
												typeof resource.link ===
												"object"
													? resource.link[0]
													: resource.link
											}
											imageHeight={0}
											descriptionLength={0}
											borderRadius={5}
											openInNewTab={true}
											className="break-words mb-2"
										/>
									) : null}
									<Typography
										className="break-words"
										dangerouslySetInnerHTML={{
											__html: resource.text,
										}}
									/>
								</div>
							)
						)}
					</div>
				</div>
			</ContextMenu>
		</>
	)
}

const View = () => {
	const { resources, resourcesPage } = useAppContext()
	const [pageNo, setPageNo] = useState(
		resourcesPage
			? resourcesPage === 1
				? resourcesPage + 1
				: resourcesPage
			: 1
	)
	const observer = useRef()

	const { hasMore, loading, error } = useGetPasteBin({ pageNo })
	// console.debug({ resourcesPage, pageNo, size: resources.length });
	const lastResourceElementRef = useCallback(
		node => {
			if (loading) return
			if (observer.current) observer.current.disconnect()
			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting && hasMore) {
					setPageNo(prevPageNumber => prevPageNumber + 1)
				}
			})
			if (node) observer.current.observe(node)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[loading, hasMore]
	)

	return (
		<>
			<ul
				className="flex flex-col-reverse flex-grow pt-6 overflow-y-scroll scrollbar-hide divide-y divide-y-reverse  divide-gray-200"
				id="resourceContainer"
			>
				{resources.length &&
				resources.filter(res => res.isVisible === false).length !==
					resources.length ? (
					resources.map(
						(resource, idx) =>
							resource.isVisible && (
								<li key={`${resource.user?.username}-${idx}`}>
									<span
										ref={
											resources.length === idx + 1
												? lastResourceElementRef
												: null
										}
									>
										{idx === 0 ? (
											<Slide
												bottom
												duration={250}
											>
												<Resource resource={resource} />
											</Slide>
										) : (
											<Resource resource={resource} />
										)}
									</span>
								</li>
							)
					)
				) : (
					<div className="flex flex-col justify-center items-center space-x-1 text-black h-full select-none space-y-8">
						<img
							src="/assets/img/noResource.png"
							alt="No Resource's Yet"
							className="md:w-[60%] w-[25%]"
						/>
						<Typography className="text-gray-500">
							No Resource's Yet
						</Typography>
					</div>
				)}
				{loading && (
					<div className="h-full flex items-center justify-center">
						<Loader />
					</div>
				)}
				{error && (
					<div className="h-full flex items-center justify-center">
						<WarningIcon
							color="error"
							fontSize="large"
						/>
					</div>
				)}
				{/* <div className="p-2 absolute right-0 bg-gray-200 rounded-md">
					<ChevronDoubleDownIcon className='h-4 w-4' />
				</div> */}
			</ul>
		</>
	)
}

export default View
