import { IconButton, Tooltip } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { ShowAttendanceFormModal } from "components/Modals"
import { useAppContext } from "context"
import { isAfter, isBefore } from "date-fns"
import { useEffect, useState } from "react"
import { useMeetingEmitter } from "utils/Emits"
import { closeClass } from "utils/closeClass"

const BackBtn = ({ className }) => {
	const {
		attended,
		classDate,
		attendanceDeadline,
		meetingRoomId,
		classCode,
	} = useAppContext()
	const [showAttendanceForm, setShowAttendanceForm] = useState(false)
	const { action } = useMeetingEmitter()

	const closeAttendanceForm = () => {
		setShowAttendanceForm(false)
		action({
			metaData: {},
			roomId: meetingRoomId || classCode,
			action: "meetingLeft",
		})
	}

	const handleBack = e => {
		if (
			attended === false &&
			(isAfter(new Date(), new Date(classDate)) &&
				isBefore(new Date(), new Date(attendanceDeadline))) === true
		)
			setShowAttendanceForm(true)
		else {
			action({
				metaData: {},
				roomId: meetingRoomId || classCode,
				action: "meetingLeft",
			})
			closeClass("endClass")
		}
	}

	useEffect(() => {
		window.addEventListener("popstate", () => {
			handleBack()
		})
		return () => {
			window.removeEventListener("popstate", () => {
				handleBack()
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<div className={className}>
				<Tooltip
					placement="bottom"
					title="Go Back"
				>
					<IconButton
						className="text-inherit"
						onClick={handleBack}
					>
						<ArrowBackIcon />
					</IconButton>
				</Tooltip>
			</div>
			<ShowAttendanceFormModal
				goingBack={true}
				showAttendanceForm={showAttendanceForm}
				closeAttendanceForm={closeAttendanceForm}
			/>
		</>
	)
}

export default BackBtn
