import { useAppContext } from "context"

const useMeetingEmitter = () => {
	const { programCode, classCode, socketPoints, videoTimeStamp } =
		useAppContext()

	const joinMeetingSocket = () => {
		socketPoints.meetingSocket.emit("join", {
			programCode,
			classCode,
		})
		console.debug('meetingSocket.emit("join")', { programCode, classCode })
	}

	const action = data => {
		socketPoints.meetingSocket.emit("action", data)
	}

	const handRaise = data => {
		socketPoints.meetingSocket.emit("handRaise", {
			...data,
			seekTime: videoTimeStamp,
		})
		console.debug("@meetingSocket.emit('handRaise'):", {
			...data,
			seekTime: videoTimeStamp,
		})
	}

	const handDown = data => {
		socketPoints.meetingSocket.emit("handDown", data)
		console.debug("@meetingSocket.emit('handDown'):", data)
	}

	const leaveClass = data => {
		socketPoints.meetingSocket.emit("leave", {
			...data,
			seekTime: videoTimeStamp,
		})
		console.debug("@meetingSocket.emit('leave'):", {
			...data,
			seekTime: videoTimeStamp,
		})
	}

	const joinedMeeting = data => {
		socketPoints.meetingSocket.emit("joinedMeeting", data)
		console.debug("@meetingSocket.emit('joinedMeeting'):", data)
	}

	return {
		joinMeetingSocket,
		action,
		handRaise,
		handDown,
		leaveClass,
		joinedMeeting,
	}
}

export default useMeetingEmitter
