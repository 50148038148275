import { Dialog, Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"
import { CheckCircleIcon } from "@heroicons/react/solid"
import { useAppContext } from "context"
import { Fragment, useState } from "react"
import { UAParser } from "ua-parser-js"
import axios from "utils/axios"
import classNames from "utils/classNames"
import { closeClass } from "utils/closeClass"

const improvementList = [
	"Nothing",
	"Conceptual Explanation",
	"Communication",
	"Interaction",
	"Hands On Implementation",
	"Doubt Solving",
	"Topics",
	"Others",
]

const ShowAttendanceFormModal = ({
	goingBack = false,
	showAttendanceForm,
	closeAttendanceForm,
}) => {
	const { programCode, classCode, type, setValue } = useAppContext()
	const [attendanceForm, setAttendanceForm] = useState({
		nps: null,
		interestedPgp: null,
		improvement: null,
		improvementDetails: "",
	})
	const [detailsMissing, setDetailsMissing] = useState(false)
	const [error, setError] = useState(null)
	const [submitted, setSubmitted] = useState(false)
	const uaParser = new UAParser().getResult()

	const getDeviceType = agent => {
		if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(agent))
			return "tablet"

		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				agent
			)
		)
			return "mobile"

		return "desktop"
	}

	const validateAttendanceForm = () => {
		if (
			attendanceForm.nps !== null &&
			attendanceForm.interestedPgp !== null
		) {
			if (attendanceForm.improvement === null && type !== "pgp") {
				setDetailsMissing(true)
				setTimeout(() => setDetailsMissing(false), 2000)
				return
			}
			if (attendanceForm.improvement === "Others" || type === "pgp") {
				attendanceForm.feedback = attendanceForm.improvementDetails
			} else {
				attendanceForm.feedback = attendanceForm.improvement
			}
			delete attendanceForm.improvementDetails
			delete attendanceForm.improvement

			if (
				Object.keys(uaParser.device).length === 0 ||
				uaParser.device.type === undefined
			)
				uaParser.device.type = getDeviceType(uaParser.ua)
			uaParser.device.approxRam = navigator
				? `${navigator.deviceMemory}GiB`
				: null
			uaParser.cpu.approxAvailableProcessors = navigator
				? navigator.hardwareConcurrency
				: null

			attendanceForm.meta = uaParser
			setDetailsMissing(false)
			submitAttendance()
		} else {
			setDetailsMissing(true)
			setTimeout(() => setDetailsMissing(false), 2000)
		}
	}

	const submitAttendance = () => {
		axios
			.post(
				`/v2/programs/attendance/${programCode}/${classCode}`,
				attendanceForm
			)
			.then(() => {
				setValue(prev => ({ ...prev, attended: true }))
				setSubmitted(true)
			})
			.catch(e => {
				setError(e.response.data.results.data.error)
			})
			.finally(() => {
				setTimeout(() => {
					closeAttendanceForm()
					setAttendanceForm({
						nps: null,
						interestedPgp: false,
						improvement: null,
						improvementDetails: "",
					})
					setError(null)
					if (goingBack) closeClass("endClass")
				}, 2000)
			})
	}

	return (
		<>
			<Transition
				appear
				show={showAttendanceForm}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closeAttendanceForm}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-lg p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<Dialog.Title
									as="h3"
									className="text-lg font-medium leading-6 text-gray-900 text-center"
								>
									Mark Your Attendance
								</Dialog.Title>
								{submitted ? (
									<div className="text-green-500 text-center mt-4">
										Attendance Submitted
									</div>
								) : null}
								{detailsMissing ? (
									<div className="text-red-500 text-center mt-4">
										Details Missing
									</div>
								) : null}
								{error ? (
									<div className="text-red-500 text-center mt-4">
										{error}
									</div>
								) : null}
								<div className="flex flex-col items-center text-center mt-4 space-y-4">
									<span className="w-full">
										<div className="text-gray-900">
											Will you recommend today's session
											to your friend?
										</div>
										<div className="flex justify-evenly mt-2 w-full">
											{Array.from({ length: 11 }).map(
												(_, idx) => (
													<button
														key={idx}
														className={classNames(
															"button w-full rounded-md py-2 px-0 mx-1",
															attendanceForm.nps ===
																idx
																? "bg-lu text-white"
																: "bg-gray-200 text-gray-900"
														)}
														onClick={() =>
															setAttendanceForm(
																prev => ({
																	...prev,
																	nps: idx,
																})
															)
														}
													>
														{idx}
													</button>
												)
											)}
										</div>
									</span>
									<span className="flex flex-col items-center">
										<div className="text-gray-900">
											{type !== "pgp" ? (
												<span>
													Are you interested in{" "}
													<span className="font-bold">
														6 Months LetsUpgrade
														FullStack Program
													</span>{" "}
													in WebDev/Data Science
												</span>
											) : (
												<span>
													Are you happy with the PGP
													Program?
												</span>
											)}
										</div>
										<div className="flex justify-evenly mt-2 w-1/2">
											<button
												className="flex justify-center items-center space-x-2 button w-full bg-gray-200 rounded-md py-2 px-0 mx-1"
												onClick={() =>
													setAttendanceForm(prev => ({
														...prev,
														interestedPgp: false,
													}))
												}
											>
												{attendanceForm.interestedPgp ===
												false ? (
													<CheckCircleIcon className="h-4 w-4 text-lu" />
												) : null}
												<span>No</span>
											</button>
											<button
												className="flex justify-center items-center space-x-2 button w-full bg-gray-200 rounded-md py-2 px-0 mx-1"
												onClick={() =>
													setAttendanceForm(prev => ({
														...prev,
														interestedPgp: true,
													}))
												}
											>
												{attendanceForm.interestedPgp ===
												true ? (
													<CheckCircleIcon className="h-4 w-4 text-lu" />
												) : null}
												<span>Yes</span>
											</button>
										</div>
									</span>
									<span>
										<div className="text-gray-900">
											What can be improved further?
										</div>
										{type !== "pgp" ? (
											<Listbox
												value={
													attendanceForm.improvement
												}
												onChange={val =>
													setAttendanceForm(prev => ({
														...prev,
														improvement: val,
													}))
												}
											>
												<div className="relative mt-1">
													<Listbox.Button className="relative w-72 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
														<span className="block truncate">
															{attendanceForm.improvement ||
																"Please share what can be improved..."}
														</span>
														<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
															<SelectorIcon
																className="h-5 w-5 text-gray-400"
																aria-hidden="true"
															/>
														</span>
													</Listbox.Button>
													<Transition
														as={Fragment}
														leave="transition ease-in duration-100"
														leaveFrom="opacity-100"
														leaveTo="opacity-0"
													>
														<Listbox.Options className="absolute mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
															{improvementList.map(
																improvement => (
																	<Listbox.Option
																		key={
																			improvement
																		}
																		className={({
																			active,
																		}) =>
																			classNames(
																				"relative cursor-default select-none py-2 pl-10 pr-4",
																				active
																					? "bg-amber-100 text-amber-900"
																					: "text-gray-900"
																			)
																		}
																		value={
																			improvement
																		}
																	>
																		{({
																			selected,
																		}) => (
																			<>
																				<span
																					className={classNames(
																						"block truncate text-left",
																						selected
																							? "font-medium"
																							: "font-normal"
																					)}
																				>
																					{
																						improvement
																					}
																				</span>
																				{selected ? (
																					<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
																						<CheckIcon
																							className="h-5 w-5"
																							aria-hidden="true"
																						/>
																					</span>
																				) : null}
																			</>
																		)}
																	</Listbox.Option>
																)
															)}
														</Listbox.Options>
													</Transition>
												</div>
											</Listbox>
										) : null}
										{attendanceForm.improvement ===
											"Others" || type === "pgp" ? (
											<textarea
												id="eventDetails"
												rows={5}
												className="px-4 py-2 pr-10 focus:outline-none scrollbar-hide mt-2 rounded-md shadow-sm block w-80 sm:text-sm border border-gray-300"
												placeholder="Please share what can be improved..."
												value={
													attendanceForm.improvementDetails
												}
												onChange={e =>
													setAttendanceForm(
														state => ({
															...state,
															improvementDetails:
																e.target.value,
														})
													)
												}
											/>
										) : null}
									</span>
								</div>
								<div className="mt-6 flex justify-center">
									<button
										type="submit"
										className="inline-flex justify-center w-1/2 px-4 py-2 text-sm font-medium text-white bg-black border border-transparent rounded-md focus:outline-none focus-visible:ring-0"
										onClick={validateAttendanceForm}
									>
										Submit
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default ShowAttendanceFormModal
