import {
	DyteCameraToggle,
	DyteGrid,
	DyteLeaveButton,
	DyteMicToggle,
	DyteNotifications,
	DyteParticipantsAudio,
	DyteScreenShareToggle,
	DyteSettingsToggle,
	extendConfig,
} from "@dytesdk/react-ui-kit"
import { useDyteMeeting, useDyteSelector } from "@dytesdk/react-web-core"
import Loader from "components/Loader"
import {
	ShowBoringMeterModal,
	ShowCamRequestModal,
	ShowMeetingLeaveConfirmationModal,
	ShowMeetingSettingsModal,
	ShowMicRequestModal,
} from "components/Modals"
import { useAppContext } from "context"
import { useEffect, useState } from "react"
import axios from "utils/axios"
import { closeClass } from "utils/closeClass"
// import CookieService from "utils/cookie.service";
import { useMeetingEmitter } from "utils/Emits"
import useBreakpoints from "utils/useBreakPoints"
import useHotkeys from "utils/useHotkeys"

const MeetingView = () => {
	// const notificationEnabled = CookieService.getAll().notification;
	const {
		participants,
		classCode,
		meetingRoomId,
		user,
		takeFeedback,
		setValue,
	} = useAppContext()
	const { isMD } = useBreakpoints()
	const { action, joinedMeeting, leaveClass } = useMeetingEmitter()
	const { meeting } = useDyteMeeting()
	const joined = useDyteSelector(m => m.self.roomJoined)
	const [meetingStates, setMeetingStates] = useState({
		prefs: { muteNotificationSounds: false },
		activeSettings: false,
		meeting: "setup",
	})
	const [activeLeaveConfirmation, setActiveLeaveConfirmation] =
		useState(false)

	const closeActiveSettings = () =>
		setMeetingStates(state => ({ ...state, activeSettings: false }))
	const closeActiveLeaveConfirmation = () => setActiveLeaveConfirmation(false)

	const updateClassStatus = (close = false) => {
		closeActiveLeaveConfirmation()
		axios.get(`/v2/lms/classes/status/${classCode}`).then(resp => {
			setValue(state => ({ ...state, ...resp.data.results.data }))
		})
	}

	useEffect(() => {
		if (user.role === "participant") {
			meeting && meeting.joinRoom()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.role])

	useEffect(() => {
		if (takeFeedback) {
			meeting?.leaveRoom()
			action({
				metaData: {},
				roomId: meetingRoomId || classCode,
				action: "meetingLeft",
			})
			closeClass("endClass")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [takeFeedback])

	const mergeWithParticipantIds = () => {
		const dyteParticipants = [
			Object.assign({}, meeting.self),
			...meeting.participants.joined
				.toArray()
				.map(p => Object.assign({}, p)),
		]
		return participants.map(p =>
			dyteParticipants.find(d => d.clientSpecificId === p.uid)
				? {
						...p,
						participantId: dyteParticipants.find(
							d => d.clientSpecificId === p.uid
						).id,
				  }
				: p
		)
	}

	const toggleMic = async () => {
		if (meeting.self.audioEnabled) {
			await meeting.self.disableAudio()
		} else {
			await meeting.self.enableAudio()
		}
	}
	const toggleCamera = async () => {
		if (meeting.self.videoEnabled) {
			await meeting.self.disableVideo()
		} else {
			await meeting.self.enableVideo()
		}
	}

	useHotkeys({ toggleMic, toggleCamera })

	// useEffect(() => {
	// 	if (!notificationEnabled)
	// 		setMeetingStates((prev) => ({
	// 			...prev,
	// 			prefs: { muteNotificationSounds: true, ...prev.prefs },
	// 		}));
	// }, [notificationEnabled]);

	const roomLeft = () => {
		updateClassStatus()
		leaveClass({
			participantId: user.participantId,
			roomId: meetingRoomId || classCode,
			uid: user.uid,
		})
		setValue(state => ({
			...state,
			user: {
				...state.user,
				role: "student",
				participantId: null,
			},
			participants: state.participants.map(p =>
				p.uid === state.user.uid ? { ...p, participantId: null } : p
			),
			roomJoined: false,
		}))
	}

	const participantJoined = participant => {
		setValue(state => ({
			...state,
			participants: state.participants.find(
				p => p.uid === participant.clientSpecificId
			)
				? [
						...state.participants.map(p =>
							p.uid === participant.clientSpecificId
								? {
										...p,
										participantId: participant.id,
								  }
								: p
						),
				  ]
				: state.participants,
		}))
	}

	const participantLeft = participant => {
		setValue(state => ({
			...state,
			participants: state.participants.find(
				p => p.uid === participant.clientSpecificId
			)
				? state.participants.map(p =>
						p.uid === participant.clientSpecificId
							? { ...p, participantId: null }
							: p
				  )
				: state.participants,
		}))
	}

	const activeSpeaker = ({ peerId }) =>
		setValue(state => ({ ...state, activeSpeaker: peerId }))

	const audioUpdate = ({ audioEnabled }) =>
		action({
			metaData: {},
			roomId: meetingRoomId || classCode,
			action: audioEnabled ? "micOn" : "micOff",
		})

	const videoUpdate = ({ videoEnabled }) =>
		action({
			metaData: {},
			roomId: meetingRoomId || classCode,
			action: videoEnabled ? "cameraOn" : "cameraOff",
		})

	const screenShareUpdate = ({ screenShareEnabled }) =>
		action({
			metaData: {},
			roomId: meetingRoomId || classCode,
			action: screenShareEnabled ? "sharedScreen" : "stopScreenShared",
		})

	useEffect(() => {
		window.meeting = meeting ? meeting : null
		if (joined) {
			// if (
			// 	![("Mac OS", "iOS")].includes(os?.name) &&
			// 	!document.fullscreenEnabled
			// ) {
			// 	document?.body
			// 		?.requestFullscreen()
			// 		.then(
			// 			async () =>
			// 				await window?.screen?.orientation?.lock("landscape")
			// 		)
			// 		.catch(() => console.error("Cannot go FullScreen"));
			// }
			action({
				metaData: {},
				roomId: meetingRoomId || classCode,
				action: "joinedMeeting",
			})
			window.onbeforeunload = () => {
				meeting.leaveRoom()
				action({
					metaData: {},
					roomId: meetingRoomId || classCode,
					action: "meetingLeft",
				})
			}
			setMeetingStates(state => ({ ...state, meeting: "joined" }))
			setValue(state => ({
				...state,
				roomJoined: true,
			}))
			joinedMeeting({
				uid: user.uid,
				participantId: meeting.self.id,
				roomId: meetingRoomId || classCode,
			})

			meeting.self.on("roomLeft", roomLeft)
			meeting.participants.joined.on(
				"participantJoined",
				participantJoined
			)
			meeting.participants.joined.on("participantLeft", participantLeft)

			meeting.participants.on("activeSpeaker", activeSpeaker)
			meeting.self.on("audioUpdate", audioUpdate)
			meeting.self.on("videoUpdate", videoUpdate)
			meeting.self.on("screenShareUpdate", screenShareUpdate)

			return () => {
				meeting.self.off("roomLeft", roomLeft)
				meeting.participants.joined.off(
					"participantJoined",
					participantJoined
				)
				meeting.participants.joined.off(
					"participantLeft",
					participantLeft
				)

				meeting.participants.off("activeSpeaker", activeSpeaker)
				meeting.self.off("audioUpdate", audioUpdate)
				meeting.self.off("videoUpdate", videoUpdate)
				meeting.self.off("screenShareUpdate", screenShareUpdate)
			}
		}
		if (meeting)
			setValue(state => ({
				...state,
				participants: mergeWithParticipantIds(),
				user: {
					...state.user,
					participantId: meeting.self.id,
				},
			}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [joined])

	return (
		<>
			<div className="px-4 py-4 flex flex-col flex-grow rounded-md h-full">
				<div className="flex flex-grow w-full h-full">
					{!joined ? (
						<div className="h-full w-full flex flex-col items-center justify-center bg-white">
							<Loader text="Getting Class Details..." />
						</div>
					) : (
						<span className="meetingControls w-full">
							<DyteGrid
								meeting={meeting}
								states={meetingStates}
								size={isMD ? "" : "sm"}
								aspectRatio={isMD ? "16:9" : "1:1"}
								onDyteStateUpdate={({ detail }) => {
									setMeetingStates(state => ({
										...state,
										...detail,
									}))
								}}
								config={extendConfig({
									root: {
										"dyte-screenshare-view": [
											["dyte-name-tag"],
											[
												"dyte-mic-toggle",
												{
													style: {
														position: "absolute",
														right: "12px",
														bottom: "12px",
													},
													size: "sm",
												},
											],
										],
									},
								})}
							/>
							<DyteParticipantsAudio meeting={meeting} />
							{process.env.NODE_ENV === "production" && (
								<DyteNotifications
									className="absolute inset-4 top-[auto]"
									meeting={meeting}
									states={meetingStates}
									config={{
										config: {
											notifications: [
												"chat",
												"participant_joined",
												"participant_left",
											],
											notification_sounds: [
												"chat",
												"participant_joined",
											],
										},
									}}
								/>
							)}
						</span>
					)}
				</div>
			</div>
			{joined && (
				<div className="md:p-4 px-4 py-2 flex flex-wrap w-full items-center justify-evenly md:justify-center meetingControls">
					<span className="shadow rounded-lg py-0.5 mx-4">
						<DyteMicToggle
							meeting={meeting}
							size={isMD ? "" : "sm"}
						/>
					</span>{" "}
					<span className="shadow rounded-lg py-0.5 mx-4">
						<DyteCameraToggle
							meeting={meeting}
							size={isMD ? "" : "sm"}
						/>
					</span>
					{typeof navigator !== "undefined" &&
						typeof navigator.mediaDevices !== "undefined" &&
						"getDisplayMedia" in navigator.mediaDevices && (
							<span className="shadow rounded-lg py-0.5 mx-4">
								<DyteScreenShareToggle
									meeting={meeting}
									size={isMD ? "" : "sm"}
								/>
							</span>
						)}
					<span className="shadow rounded-lg py-0.5 mx-4">
						<DyteSettingsToggle
							states={meetingStates}
							onDyteStateUpdate={({ detail }) => {
								setMeetingStates(state => ({
									...state,
									activeSettings: detail.activeSettings,
								}))
							}}
							size={isMD ? "" : "sm"}
						/>
					</span>{" "}
					<span className="shadow rounded-lg py-0.5 mx-4">
						<DyteLeaveButton
							states={{ activeLeaveConfirmation }}
							onDyteStateUpdate={({ detail }) => {
								setActiveLeaveConfirmation(
									detail.activeLeaveConfirmation
								)
							}}
							size={isMD ? "" : "sm"}
						/>
					</span>
				</div>
			)}
			<ShowBoringMeterModal />
			<ShowMicRequestModal />
			<ShowCamRequestModal />
			{meetingStates.activeSettings ? (
				<ShowMeetingSettingsModal
					meetingStates={meetingStates}
					setMeetingStates={setMeetingStates}
					closeActiveSettings={closeActiveSettings}
				/>
			) : null}
			{activeLeaveConfirmation ? (
				<ShowMeetingLeaveConfirmationModal
					activeLeaveConfirmation={activeLeaveConfirmation}
					setActiveLeaveConfirmation={setActiveLeaveConfirmation}
					closeActiveLeaveConfirmation={closeActiveLeaveConfirmation}
				/>
			) : null}
		</>
	)
}

export default MeetingView
