import { Typography } from "@material-ui/core"
import { useAppContext } from "context"
import classNames from "utils/classNames"

const Agenda = () => {
	const { agendas } = useAppContext()
	return agendas.length ? (
		agendas.map((agenda, idx) => (
			<li
				key={agenda.id}
				className={classNames(
					"flex justify-between items-center text-black bg-white px-4 py-4 sm:px-6 select-none"
					// agenda.isCompleted ? "opacity-40" : ""
				)}
			>
				<span className="flex-1 flex space-x-4 select-text">
					<div className="flex items-center">
						<input
							type="checkbox"
							className="focus:ring-0 focus:outline-none h-4 w-4 text-lu border-gray-300 rounded"
							checked={agenda.isCompleted}
							readOnly
						/>
					</div>
					<div
						className={classNames(
							"flex-1 text-sm",
							agenda.isCompleted
								? "text-gray-500"
								: "text-gray-900 "
						)}
					>
						{agenda.text}
					</div>
				</span>
			</li>
		))
	) : (
		<div className="flex flex-col justify-center items-center space-x-1 text-black h-full select-none space-y-8">
			<img
				src="/assets/img/noAgenda.png"
				alt="No Agenda's Listed"
				className="md:w-[60%] w-[25%]"
			/>
			<Typography className="text-gray-500">
				No Agenda's Listed
			</Typography>
		</div>
	)
}

export default Agenda
