import React from "react"
import MeetingEvents from "./MeetingEvents"
import ChatEvents from "./ChatEvents"
import BoringMeterEvents from "./BoringMeterEvents"
import PasteBinEvents from "./PasteBinEvents"
import SessionEvents from "./SessionEvents"

const Events = () => {
	return (
		<>
			<MeetingEvents />
			<ChatEvents />
			<BoringMeterEvents />
			<PasteBinEvents />
			<SessionEvents />
		</>
	)
}

export default Events
