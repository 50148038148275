export const closeClass = msg => {
	console.debug(`@Iframe: ${msg}`)
	const urls = {
		production: `https://letsupgrade.in`,
		development: `http://localhost:3333`,
	}

	const programCode = window.localStorage.getItem("programCode")

	const route = programCode ? `/my-programs/${programCode}` : "/"

	if (window.opener === null) {
		console.error("Opener missing")
		window.open(`${urls[process.env.NODE_ENV]}${route}`)
	}

	// window.opener.postMessage(msg, "*");
	// window.opener.focus();
	if (process.env.NODE_ENV !== "developement") window.close()
}
