import { useAppContext } from "context"

const useChatEmitter = () => {
	const { programCode, classCode, socketPoints, videoTimeStamp } =
		useAppContext()

	const joinChatSocket = value => {
		socketPoints.chatSocket.emit("join", {
			programCode,
			classCode,
		})
		console.debug('chatSocket.emit("join")', { programCode, classCode })
	}

	const sendMessage = message => {
		socketPoints.chatSocket.emit("message", {
			...message,
			seekTime: videoTimeStamp,
		})
		console.debug("@chatSocket.emit('message'):", {
			...message,
			seekTime: videoTimeStamp,
		})
	}

	return {
		joinChatSocket,
		sendMessage,
	}
}

export default useChatEmitter
