import { Dialog, Tab, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { AgendasView, ChatView, ParticipantsView } from "components"
import { Fragment } from "react"

const TITLES = {
	0: "Agenda",
	1: "Chat",
	2: "Upgraders",
}

const ShowTabs = ({ activeTab, showTabs, closeTabs }) => {
	return (
		<>
			<Transition
				show={showTabs}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 top-[4.3rem] bottom-[3.75rem] overflow-hidden"
					onClose={() => false}
				>
					<div className="absolute inset-0 overflow-hidden">
						{/* <Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-50' />
						</Transition.Child> */}
						<div className="pointer-events-none flex w-screen h-full">
							<Transition.Child
								as={Fragment}
								enter="transform"
								enterFrom="translate-y-full"
								enterTo="translate-y-0"
								leave="transform transition ease-in duration-200"
								leaveFrom="translate-y-0"
								leaveTo="translate-y-full"
							>
								<div className="pointer-events-auto w-screen">
									<div className="inline-flex flex-col divide-y overflow-hidden text-left align-middle bg-white w-screen h-full">
										<div className="flex items-center justify-between px-6 py-2">
											<Dialog.Title
												as="h3"
												className="text-base font-medium leading-6 text-gray-900"
											>
												{TITLES[activeTab]}
											</Dialog.Title>
											<div className="ml-3 flex h-7 items-center">
												<button
													type="button"
													className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
													onClick={closeTabs}
												>
													<span className="sr-only">
														Close panel
													</span>
													<XIcon
														className="h-6 w-6"
														aria-hidden="true"
													/>
												</button>
											</div>
										</div>
										<div className="h-full flex flex-col flex-1">
											<Tab.Group
												selectedIndex={activeTab}
											>
												<Tab.List className="hidden">
													<Tab />
													<Tab />
													<Tab />
												</Tab.List>
												<Tab.Panels className="flex-1">
													<Tab.Panel className="bg-white w-full h-full outline-none transition-all duration-200">
														<AgendasView />
													</Tab.Panel>
													<Tab.Panel className="bg-white w-full h-full outline-none transition-all duration-200">
														<ChatView />
													</Tab.Panel>
													<Tab.Panel className="bg-white w-full h-full outline-none transition-all duration-200">
														<ParticipantsView />
													</Tab.Panel>
												</Tab.Panels>
											</Tab.Group>
										</div>
									</div>
								</div>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default ShowTabs
