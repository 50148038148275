import { useAppContext } from "context"
import React, { useEffect } from "react"
import axios from "utils/axios"
import { closeClass } from "utils/closeClass"
import CookieService from "utils/cookie.service"
import { useMeetingEmitter } from "utils/Emits"

const MeetingEvents = () => {
	const { classCode, user, meetingId, socketPoints, setValue } =
		useAppContext()
	const { joinMeetingSocket } = useMeetingEmitter()

	const meetingSocketConnect = () => {
		// joinMeetingSocket();
		console.debug("@meetingSocket Connected via default")
	}

	// const meetingSocketConnected = () => {
	// 	console.debug("@meetingSocket Connected via custom");
	// 	joinMeetingSocket();
	// };

	const meetingSocketIoReconnect = attempt => {
		console.debug("@meetingSocket reconnect", attempt)
		joinMeetingSocket()
	}

	const meetingSocketIoReconnectAttempt = attempt => {
		console.debug("@meetingSocket reconnect_attempt", attempt)
	}

	const meetingSocketIoReconnectError = error => {
		console.debug("@meetingSocket reconnect_error", error.message)
	}
	const meetingSocketIoReconnectFailed = () => {
		console.debug("@meetingSocket reconnect_failed")
		setValue(state => ({
			...state,
			socketError: true,
		}))
		closeClass("meetingSocketError")
	}

	const meetingSocketDisconnect = reason => {
		console.debug("@meetingSocket Disconnected: ", reason)
		setValue(state => ({
			...state,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					meetingSocket: false,
				},
				error: { ...state.socketState.error, meetingSocket: false },
			},
			participants: state.participants.filter(p => p.uid !== user.uid),
			chats: state.participants.find(p => p.uid === user.uid)
				? [
						{
							type: "member",
							msg: `${
								state.participants.find(p => p.uid === user.uid)
									.name
							} Left`,
							createdAt: new Date().toISOString(),
						},
						...state.chats,
				  ]
				: state.chats,
		}))
	}

	const meetingSocketError = data => {
		console.error("@meetingSocket ERROR: ", data)
		setValue(state => ({
			...state,
			socketError: true,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					meetingSocket: false,
				},
				error: { ...state.socketState.error, meetingSocket: false },
			},
		}))
		closeClass("meetingSocketError")
	}

	const meetingSocketJoined = data => {
		console.debug("@meetingSocket Joined: ", data)
		setValue(state => {
			const me = {
				uid: state.user.uid,
				name: state.user.name,
				username: state.user.username,
				profileImageUrl: state.user.profileImageUrl,
				role: state.user.role,
				handRaiseStatus: null,
				lastDoubtId: null,
				permissions: { chat: { isBanned: false, time: 0 } },
			}

			return {
				...state,
				meetingRoomId: data.roomId,
				socketState: {
					...state.socketState,
					connection: {
						...state.socketState.connection,
						meetingSocket: true,
					},
					error: { ...state.socketState.error, meetingSocket: false },
				},
				participants: state.participants.find(p => p.uid === me.uid)
					? [...state.participants.filter(p => p.uid !== me.uid), me]
					: [...state.participants, me],
			}
		})
	}

	const meetingSocketNewMember = newUser => {
		console.debug("@meetingSocket.on('newMember'):", newUser)

		if (JSON.parse(CookieService.getAll().notification)) {
			const notifier = new Audio("/assets/audio/ding.mp3")
			notifier.crossOrigin = "anonymous"
			notifier.addEventListener("canplaythrough", () => {
				notifier.play()
			})
		}
		let following = false
		// document.getElementById("notification").play();
		if (newUser.uid === user.uid) {
			closeClass("duplicate")
		} else {
			// newUser = {
			// 	...newUser,
			// 	lastDoubtId: null,
			// };
			// setValue((state) => ({
			// 	...state,
			// 	participants: state.participants.find(
			// 		(p) => p.uid === newUser.uid
			// 	)
			// 		? [
			// 				...state.participants.filter(
			// 					(p) => p.uid !== newUser.uid
			// 				),
			// 				newUser,
			// 		  ]
			// 		: [...state.participants, newUser],
			// 	chats: [
			// 		{
			// 			type: "member",
			// 			msg: `${newUser.name} Joined`,
			// 			createdAt: new Date().toISOString(),
			// 		},
			// 		...state.chats,
			// 	],
			// }));
			axios
				.get(`/users/${newUser.username}`)
				.then(({ data }) => {
					following = data.results.data.following
				})
				.catch(e => {
					console.error(e.response)
				})
				.finally(() => {
					newUser = {
						...newUser,
						following,
						lastDoubtId: null,
					}
					setValue(state => ({
						...state,
						participants: state.participants.find(
							p => p.uid === newUser.uid
						)
							? [
									...state.participants.filter(
										p => p.uid !== newUser.uid
									),
									newUser,
							  ]
							: [...state.participants, newUser],
						// chats: [
						// 	{
						// 		type: "member",
						// 		msg: `${newUser.name} Joined`,
						// 		createdAt: new Date().toISOString(),
						// 	},
						// 	...state.chats,
						// ],
					}))
				})
		}
	}

	const meetingSocketLive = ({ isLive, downstreamUrl }) => {
		if (isLive) {
			console.debug("@meetingSocket.on('live'):", isLive)
			setTimeout(() => {
				setValue(state => ({
					...state,
					isLive,
					videoDetails: {
						link: downstreamUrl,
					},
					downstreamUrl,
				}))
			}, 15 * 1000)
		} else {
			console.debug("@meetingSocket.on('live'):", isLive)
			setValue(state => ({
				...state,
				isLive,
				takeFeedback: true,
			}))
		}
	}

	const meetingSocketHandRaise = ({
		_id = null,
		tempId = null,
		user,
		question,
	}) => {
		console.debug("@meetingSocket.on('handRaise'):", {
			_id,
			tempId,
			uid: user.uid,
			question,
		})
		setValue(state => {
			return {
				...state,
				participants: state.participants.map(p => {
					if (p.uid === user.uid) p.handRaiseStatus = "raised"
					return p
				}),
				doubts: state.doubts.find(x => x.tempId === tempId)
					? state.doubts.map(d => {
							if (d.tempId === tempId) d._id = _id
							return d
					  })
					: [
							{
								// tempId: nanoid(16),
								user,
								question,
								answer: null,
								createdAt: new Date().toISOString(),
							},
							...state.doubts,
					  ],
			}
		})
		const doubtContainer = document.getElementById("doubtContainer")
		doubtContainer &&
			doubtContainer.scrollTo({
				top: doubtContainer.scrollHeight,
				behavior: "smooth",
			})
	}

	const meetingSocketSelfHandRaise = ({ _id, tempId }) => {
		console.debug("@meetingSocket.on('selfHandRaise'):", { _id, tempId })
		setValue(state => ({
			...state,
			doubts: [
				...state.doubts.map(d =>
					d.tempId === tempId ? { ...d, _id } : d
				),
			],
		}))
	}

	const meetingSocketAnswered = ({
		uid,
		_id,
		question,
		solution,
		status,
	}) => {
		console.debug("@meetingSocket.on('answered'):", {
			uid,
			_id,
			solution,
		})
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) p.handRaiseStatus = null
				return p
			}),
			doubts: state.doubts.map(d => {
				if (d._id === _id) d.answer = solution
				return d
			}),
			user: {
				...state.user,
				handRaiseStatus:
					state.user.uid === uid ? null : state.user.handRaiseStatus,
			},
		}))
	}

	const meetingSocketHandDown = ({ uid }) => {
		console.debug("@meetingSocket.on('handDown'):", uid)
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) p.handRaiseStatus = null
				return p
			}),
		}))
	}

	const meetingSocketJoinedMeeting = ({ uid, participantId }) => {
		console.debug("@meetingSocket.on('joinedMeeting'):", participantId)
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) p.participantId = participantId
				return p
			}),
		}))
	}

	const meetingSocketJoinClass = ({ uid }) => {
		console.debug("@meetingSocket.on('joinClass'):", uid)
		if (uid === user.uid) {
			axios
				.post(`/v2.5/lms/classes/dyte/meeting/${meetingId}/add`)
				.then(res => {
					setValue(prev => ({
						...prev,
						user: {
							...prev.user,
							handRaiseStatus:
								prev.user.uid === uid
									? "pending"
									: prev.user.handRaiseStatus,
							role:
								prev.user.uid === uid
									? "participant"
									: prev.user.role,
						},
						authToken: res.data.results.data.authToken,
						hasEnded: false,
					}))
				})
				.catch(e => {
					console.error(e)
				})
		}
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) p.handRaiseStatus = null
				return p
			}),
		}))
	}

	const meetingSocketautoAdmitState = ({ enabled }) => {
		console.debug("@meetingSocket.on('autoAdmitState'):", enabled)
		setValue(state => ({
			...state,
			autoAdmit: enabled,
		}))
	}

	const meetingSocketAccepted = ({ uid }) => {
		console.debug(
			"@meetingSocket.on('accepted'):",
			uid,
			"=>",
			user.uid === uid ? "Me" : "Not Me"
		)
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) p.handRaiseStatus = null
				return p
			}),
			user: {
				...state.user,
				handRaiseStatus:
					state.user.uid === uid
						? "pending"
						: state.user.handRaiseStatus,
				role: state.user.uid === uid ? "participant" : state.user.role,
			},
		}))
	}

	const meetingSocketRejected = ({ uid }) => {
		console.debug(
			"@meetingSocket.on('rejected'):",
			uid,
			"=>",
			user.uid === uid ? "Me" : "Not Me"
		)
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) p.handRaiseStatus = null
				return p
			}),
			user: {
				...state.user,
				handRaiseStatus:
					state.user.uid === uid ? null : state.user.handRaiseStatus,
				role: state.user.uid === uid ? "student" : state.user.role,
			},
		}))
	}

	const meetingSocketKick = ({ participantId }) => {
		console.debug("@meetingSocket.on('kick'):", participantId)
		axios.get(`/v2/lms/classes/status/${classCode}`).then(resp => {
			setValue(state => ({
				...state,
				participants: state.participants.map(p => {
					if (p.participantId === participantId) {
						p.handRaiseStatus = null
						p.participantId = null
					}
					return p
				}),
				user: {
					...state.user,
					handRaiseStatus:
						state.user.participantId === participantId
							? null
							: state.user.handRaiseStatus,
					role:
						state.user.participantId === participantId
							? "student"
							: state.user.role,
					participantId:
						state.user.participantId === participantId
							? null
							: state.user.participantId,
				},
				...resp.data.results.data,
			}))
		})
	}

	// const meetingSocketLeave = ({ participantId }) => {
	// 	console.debug("@meetingSocket.on('leave'):", participantId);
	// 	setValue((state) => ({
	// 		...state,
	// 		user: {
	// 			...state.user,
	// 			handRaiseStatus:
	// 				state.user.participantId === participantId ||
	// 				state.user.uid === participantId
	// 					? null
	// 					: state.user.handRaiseStatus,
	// 			role:
	// 				state.user.participantId === participantId ||
	// 				state.user.uid === participantId
	// 					? "student"
	// 					: state.user.role,
	// 		},
	// 	}));
	// };

	const meetingSocketMic = ({ participantId }) => {
		console.debug(
			"@meetingSocket.on('mic'):",
			participantId,
			"=>",
			user.participantId === participantId ? "Me" : "Not Me"
		)
		setValue(state => ({
			...state,
			user: {
				...state.user,
				misc: {
					...state.user.misc,
					micReq: state.user.participantId === participantId,
				},
			},
		}))
	}

	const meetingSocketCamera = ({ participantId }) => {
		console.debug(
			"@meetingSocket.on('camera'):",
			participantId,
			"=>",
			user.participantId === participantId ? "Me" : "Not Me"
		)
		setValue(state => ({
			...state,
			user: {
				...state.user,
				misc: {
					...state.user.misc,
					camReq: state.user.participantId === participantId,
				},
			},
		}))
	}

	const meetingSocketMemberLeft = ({ uid }) => {
		console.debug("@meetingSocket.on('memberLeft'):", { uid })
		setValue(state => ({
			...state,
			participants: state.participants.filter(p => p.uid !== uid),
			// chats: state.participants.find((p) => p.uid === uid)
			// 	? [
			// 		{
			// 			type: "member",
			// 			msg: `${state.participants.find((p) => p.uid === uid)
			// 				.name
			// 				} Left`,
			// 			createdAt: new Date().toISOString(),
			// 		},
			// 		...state.chats,
			// 	]
			// 	: state.chats,
		}))
	}

	useEffect(() => {
		socketPoints.meetingSocket.on("connect", meetingSocketConnect)
		// socketPoints.meetingSocket.on("connected", meetingSocketConnected);
		socketPoints.meetingSocket.io.on("reconnect", meetingSocketIoReconnect)
		socketPoints.meetingSocket.io.on(
			"reconnect_attempt",
			meetingSocketIoReconnectAttempt
		)
		socketPoints.meetingSocket.io.on(
			"reconnect_error",
			meetingSocketIoReconnectError
		)
		socketPoints.meetingSocket.io.on(
			"reconnect_failed",
			meetingSocketIoReconnectFailed
		)
		socketPoints.meetingSocket.on("disconnect", meetingSocketDisconnect)
		socketPoints.meetingSocket.on("error", meetingSocketError)
		socketPoints.meetingSocket.on("joined", meetingSocketJoined)
		socketPoints.meetingSocket.on("newMember", meetingSocketNewMember)
		socketPoints.meetingSocket.on("live", meetingSocketLive)
		socketPoints.meetingSocket.on("handRaise", meetingSocketHandRaise)
		socketPoints.meetingSocket.on(
			"selfHandRaise",
			meetingSocketSelfHandRaise
		)
		socketPoints.meetingSocket.on("answered", meetingSocketAnswered)
		socketPoints.meetingSocket.on("handDown", meetingSocketHandDown)
		socketPoints.meetingSocket.on(
			"joinedMeeting",
			meetingSocketJoinedMeeting
		)
		socketPoints.meetingSocket.on("joinClass", meetingSocketJoinClass)
		socketPoints.meetingSocket.on(
			"autoAdmitState",
			meetingSocketautoAdmitState
		)
		socketPoints.meetingSocket.on("accepted", meetingSocketAccepted)
		socketPoints.meetingSocket.on("rejected", meetingSocketRejected)
		socketPoints.meetingSocket.on("kick", meetingSocketKick)
		// socketPoints.meetingSocket.on("leave",meetingSocketLeave);
		socketPoints.meetingSocket.on("mic", meetingSocketMic)
		socketPoints.meetingSocket.on("camera", meetingSocketCamera)
		socketPoints.meetingSocket.on("memberLeft", meetingSocketMemberLeft)

		return () => {
			socketPoints.meetingSocket.off("connect", meetingSocketConnect)
			// socketPoints.meetingSocket.off("connected", meetingSocketConnected);
			socketPoints.meetingSocket.io.off(
				"reconnect",
				meetingSocketIoReconnect
			)
			socketPoints.meetingSocket.io.off(
				"reconnect_attempt",
				meetingSocketIoReconnectAttempt
			)
			socketPoints.meetingSocket.io.off(
				"reconnect_error",
				meetingSocketIoReconnectError
			)
			socketPoints.meetingSocket.io.off(
				"reconnect_failed",
				meetingSocketIoReconnectFailed
			)
			socketPoints.meetingSocket.off(
				"disconnect",
				meetingSocketDisconnect
			)
			socketPoints.meetingSocket.off("error", meetingSocketError)
			socketPoints.meetingSocket.off("joined", meetingSocketJoined)
			socketPoints.meetingSocket.off("newMember", meetingSocketNewMember)
			socketPoints.meetingSocket.off("live", meetingSocketLive)
			socketPoints.meetingSocket.off("handRaise", meetingSocketHandRaise)
			socketPoints.meetingSocket.off(
				"selfHandRaise",
				meetingSocketSelfHandRaise
			)
			socketPoints.meetingSocket.off("answered", meetingSocketAnswered)
			socketPoints.meetingSocket.off("handDown", meetingSocketHandDown)
			socketPoints.meetingSocket.off(
				"joinedMeeting",
				meetingSocketJoinedMeeting
			)
			socketPoints.meetingSocket.off("joinClass", meetingSocketJoinClass)
			socketPoints.meetingSocket.off(
				"autoAdmitState",
				meetingSocketautoAdmitState
			)
			socketPoints.meetingSocket.off("accepted", meetingSocketAccepted)
			socketPoints.meetingSocket.off("rejected", meetingSocketRejected)
			socketPoints.meetingSocket.off("kick", meetingSocketKick)
			// socketPoints.meetingSocket.off("leave",meetingSocketLeave);
			socketPoints.meetingSocket.off("mic", meetingSocketMic)
			socketPoints.meetingSocket.off("camera", meetingSocketCamera)
			socketPoints.meetingSocket.off(
				"memberLeft",
				meetingSocketMemberLeft
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <></>
}

export default MeetingEvents
