// import { BanIcon, ReplyIcon, TrashIcon } from "@heroicons/react/outline";
import { Typography } from "@material-ui/core"
import WarningIcon from "@material-ui/icons/Warning"
import { Loader } from "components"
import { useAppContext } from "context"
import { useCallback, useEffect, useRef, useState } from "react"
import classNames from "utils/classNames"
// import { useChatEmitter } from "utils/Emits";
import useGetMessage from "utils/useGetMessage"
import { isInViewport } from "utils/viewPort"
import Message from "../Message"

const View = ({ messages, setMsgToReplyTo }) => {
	// const [msgRefs, setMsgRefs] = useState({});
	// const [repliedMsg, setRepliedMsg] = useState(null);
	// const [focusedMsg, setFocusedMsg] = useState();
	const { unread, chatPage, setValue } = useAppContext()
	// const { banUser, deleteMessage } = useChatEmitter();
	const [pageNo, setPageNo] = useState(
		chatPage ? (chatPage === 1 ? chatPage + 1 : chatPage) : 1
	)
	const lastMsgObserver = useRef()
	// const replyMsgObserver = useRef();

	// const menuOptions = [
	// 	{
	// 		label: "Timeout",
	// 		icon: BanIcon,
	// 		isFor: "user",
	// 		disabled: true,
	// 		action: ({ e, msg, msgUser }) => {
	// 			banUser({
	// 				uid: msgUser?.uid,
	// 				roomId: chatRoomId || classCode,
	// 			});
	// 			setValue((state) => ({
	// 				...state,
	// 				participants: state.participants.map((p) =>
	// 					p.uid === msgUser.uid
	// 						? {
	// 							...p,
	// 							permissions: {
	// 								chat: {
	// 									banned: true,
	// 									time: 120,
	// 								},
	// 							},
	// 						}
	// 						: p
	// 				),
	// 			}));
	// 			setTimeout(() => {
	// 				setValue((state) => ({
	// 					...state,
	// 					participants: state.participants.map((p) =>
	// 						p.uid === msgUser.uid
	// 							? {
	// 								...p,
	// 								permissions: {
	// 									chat: {
	// 										banned: false,
	// 										time: 0,
	// 									},
	// 								},
	// 							}
	// 							: p
	// 					),
	// 				}));
	// 			}, 120 * 1000);
	// 			console.debug("Timeout");
	// 		},
	// 	},
	// 	{
	// 		label: "Delete",
	// 		icon: TrashIcon,
	// 		isFor: "msg",
	// 		disabled: "isDeleted",
	// 		action: ({ e, msg }) => {
	// 			deleteMessage({
	// 				_id: msg._id,
	// 				roomId: chatRoomId || classCode,
	// 			});
	// 			setValue((state) => ({
	// 				...state,
	// 				chats: state.chats.map((chat) =>
	// 					chat._id === msg._id
	// 						? { ...chat, isDeleted: true }
	// 						: chat.replyTo?._id === msg._id
	// 							? {
	// 								...chat,
	// 								replyTo: {
	// 									...chat.replyTo,
	// 									isDeleted: true,
	// 								},
	// 							}
	// 							: chat
	// 				),
	// 			}));
	// 			console.debug("Delete");
	// 		},
	// 	},
	// 	{
	// 		label: "Reply",
	// 		icon: ReplyIcon,
	// 		isFor: "msg",
	// 		disabled: "isDeleted",
	// 		action: ({ e, msg, msgUser }) => {
	// 			console.debug({ ...msg, user: msgUser });
	// 			setMsgToReplyTo({ ...msg, user: msgUser });
	// 			console.debug("Reply");
	// 		},
	// 	},
	// ];

	const { hasMore, loading, error } = useGetMessage({ pageNo })
	// console.debug({ chatPage, pageNo, size: messages.length });

	const lastMsgElementRef = useCallback(
		node => {
			if (loading) return
			if (lastMsgObserver.current) lastMsgObserver.current.disconnect()
			lastMsgObserver.current = new IntersectionObserver(entries => {
				console.debug({
					intersecting: entries[0].isIntersecting,
					hasMore,
				})
				if (entries[0].isIntersecting && hasMore) {
					setPageNo(prevPageNumber => prevPageNumber + 1)
				}
			})
			if (node) lastMsgObserver.current.observe(node)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[loading, hasMore]
	)

	// const replyMsgElementRef = useCallback((node) => {
	// 	if (replyMsgObserver.current) replyMsgObserver.current.disconnect();
	// 	replyMsgObserver.current = new IntersectionObserver((entries) => {
	// 		if (entries[0].isIntersecting) {
	// 			document.querySelector(".focused")?.classList.add("pulse");
	// 			setTimeout(() => {
	// 				document
	// 					.querySelector(".focused")
	// 					?.classList.remove("pulse");
	// 				setFocusedMsg();
	// 			}, 2000);
	// 		}
	// 	});
	// 	if (node) replyMsgObserver.current.observe(node);
	// }, []);

	// useEffect(() => {
	// 	const refs = messages.reduce((acc, value) => {
	// 		if (value?.type !== "dateDiff") acc[value._id] = React.createRef();
	// 		return acc;
	// 	}, {});
	// 	setMsgRefs(refs);
	// }, [messages]);

	// useEffect(() => {
	// 	if (repliedMsg === null) return;
	// 	if (messages.findIndex((m) => m._id === repliedMsg) >= 0) {
	// 		msgRefs[repliedMsg]?.current?.scrollIntoView({
	// 			behavior: "smooth",
	// 			block: "center",
	// 		});
	// 		msgRefs[repliedMsg]?.current?.focus();
	// 		if (
	// 			!(
	// 				messages.findIndex((m) => m._id === repliedMsg) >=
	// 				messages.length - 5
	// 			)
	// 		) {
	// 			setFocusedMsg(repliedMsg);
	// 			setRepliedMsg(null);
	// 		}
	// 	} else {
	// 		setRepliedMsg(null);
	// 		alert("Could not find msg, please scroll up!");
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [msgRefs, repliedMsg]);

	useEffect(() => {
		//scroll
		document.addEventListener("scroll", () => {
			if (unread.chat && isInViewport("#chatEnd"))
				setValue(state => ({
					...state,
					unread: {
						...state.unread,
						chat: 0,
					},
				}))
		})
		return () => {
			document.removeEventListener("scroll", () => {
				if (unread.chat && isInViewport("#chatEnd"))
					setValue(state => ({
						...state,
						unread: {
							...state.unread,
							chat: 0,
						},
					}))
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<div
				className="flex flex-col-reverse flex-grow pt-4 overflow-y-scroll scrollbar-hide h-px divide-y"
				id="chatContainer"
			>
				<span id="chatEnd" />
				{messages.length ? (
					messages.map((msgGroup, idx) => (
						<div
							key={msgGroup?.user?.uid + msgGroup.type + idx}
							ref={
								messages.length === idx + 1
									? lastMsgElementRef
									: null
							}
						>
							<Message msgGroup={msgGroup} />
						</div>
					))
				) : (
					<div
						className={classNames(
							"flex flex-col justify-center items-center space-x-1 text-black h-full select-none space-y-8"
						)}
					>
						<img
							src="/assets/img/noMessage.png"
							alt="No Chat's Yet"
							className="md:w-[60%] w-[25%]"
						/>
						<Typography className="text-gray-500">
							No Chat's Yet
						</Typography>
					</div>
				)}
				{loading && (
					<div className="h-full flex items-center justify-center">
						<Loader />
					</div>
				)}
				{error && (
					<div className="h-full flex items-center justify-center">
						<WarningIcon
							color="error"
							fontSize="large"
						/>
					</div>
				)}
				<span id="chatStart" />
			</div>
		</>
	)
}

export default View
