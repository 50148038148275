import { useAppContext } from "context"

const usePasteBinEmitter = () => {
	const { socketPoints, programCode, classCode } = useAppContext()

	const joinPasteBinSocket = value => {
		socketPoints.pasteBinSocket.emit("join", {
			programCode,
			classCode,
		})
		console.debug('pasteBinSocket.emit("join")', { programCode, classCode })
	}

	return {
		joinPasteBinSocket,
	}
}

export default usePasteBinEmitter
