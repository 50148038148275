import { useAppContext } from "context"
import { useEffect, useState } from "react"
import axios from "utils/axios"
import groupifyChat from "./groupifyChat"

const useGetMessage = ({ pageNo }) => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [hasMore, setHasMore] = useState(false)
	const { chats, chatPage, classCode, setValue } = useAppContext()

	useEffect(() => {
		setError(false)
		if (chatPage === pageNo)
			setValue(state => ({
				...state,
				chats,
			}))
		else {
			setLoading(true)
			axios({
				method: "GET",
				url: `https://service.letsupgrade.in/v1/chats/${classCode}`,
				params: { page: pageNo, limit: 10 },
			})
				.then(res => {
					if (res.data.results.data.length)
						setValue(state => ({
							...state,
							chats: groupifyChat(
								res.data.results.data.map(m => ({
									...m,
									isDeleted: false,
								})),
								state.chats
							),
							chatPage: state.chatPage + 1,
						}))
					setHasMore(res.data.results.data.length > 0)
					setLoading(false)
				})
				.catch(e => {
					if (axios.isCancel(e)) return
					setLoading(false)
					setError(true)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNo])

	return { loading, error, hasMore }
}

export default useGetMessage
