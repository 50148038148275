import React, { useEffect } from "react"
import { useDyteClient, DyteProvider } from "@dytesdk/react-web-core"
import { useAppContext } from "context"
import { Loader } from "components"

const Meeting = ({ children }) => {
	const { authToken, hasEnded } = useAppContext()
	const [client, initClient] = useDyteClient()

	useEffect(() => {
		if (!hasEnded && !client)
			initClient({
				authToken,
				defaults: {
					audio: false,
					video: false,
				},
			})
		// return () => client?.leaveRoom();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authToken])

	useEffect(() => (window.client = client), [client])

	return (
		<>
			{hasEnded ? (
				<>{children}</>
			) : client ? (
				<DyteProvider value={client}>{children}</DyteProvider>
			) : (
				<div className="h-screen w-screen flex flex-col items-center justify-center bg-white">
					<Loader text="Loading Class" />
				</div>
			)}
		</>
	)
}

export default Meeting
